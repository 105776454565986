.floating-text {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  height: 0;
  color: var(--gf-neutral-50);
  font-size: 41px;
  font-weight: 700;
}

.banner {
  position: relative;
  .btn-back {
    left: 2.5em;
    top: 6em;
    background: var(--gf-primary-50);
    height: 30px;
    width: 30px;
    border-radius: 100%;
    opacity: 0.5;
    padding: 0;
    position: absolute;

    &:hover {
      color: var(--primary);
    }
  }

  img {
    width: 100%;
    z-index: -100;
  }
}
