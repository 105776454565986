.faqs-page {
  @include MyNavbar(64px);
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
  .redemption-navbar {
    height: 30px;
  }
  .title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .faq__banner {
    position: relative;
    @include media-breakpoint-down(sm) {
      padding: 0px;
      top: -8px;
      display: none;
      // left: -16px;
      // width: calc(100vw + 32px);
    }
  }

  .content-faq {
    margin-bottom: 50px;
    margin-top: 4em;
    .title {
      text-align: center;
    }
    .panel-heading {
      background-color: transparent;
      border-color: var(--gf-neutral-200) !important;
      border-bottom: 1px px solid var(--gf-neutral-200) !important;
      padding-top: 2em;
      padding-bottom: 2em;
      padding-left: 0;
      padding-right: 0;
      position: relative;
      &:after {
        content: '';
        background-color: var(--gf-neutral-200);
        height: 1px;
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }

    .panel-title {
      color: var(--gf-neutral-500);
      position: relative;
      @include font-16-bold;
      &:hover {
        text-decoration: none;
        color: var(--gf-neutral-400);
      }
    }
    .collapse {
      padding: 2em 0px;
      a {
        color: var(--primary);
      }
    }

    .panel-title a {
      width: 100% !important;
      display: block !important;
      line-height: 1.5em;
    }

    .panel {
      border: none;
      background-color: transparent;
      box-shadow: none;
      border-bottom: 1px solid #eee;
    }

    .panel-body {
      border-top: none !important;
      border-bottom-color: #ddd;
      padding-top: 0.6em;
      padding-bottom: 2.4em;
      color: #606470;
      font-size: 16px;
    }
  }
  .card-form-email {
    background-color: #9ad1cc;
    margin-bottom: 60px;
    .card-body {
      padding: 2.6em 3.3em;
    }
  }
  .form-control {
    border-radius: var(--gf-border-radius-m);
    background-color: var(--gf-neutral-100);
    font-size: 16px;
    padding: 1.2em;
    border-color: transparent;
  }
}

@media screen and (max-width: 767px) {
  .faqs-page {
    .title {
      font-size: 24px;
      line-height: 1.2;
    }
    form {
      position: relative;
      &:after {
        @include full-width-container;
      }
    }
    .card-form-email {
      .card-body {
        padding: 2.6em 15px;
      }
    }
  }
}
