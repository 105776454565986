.modal-help {
  .modal-title {
    // font-size: 24px;
    color: var(--primary);
  }
  .modal-body {
    padding-bottom: 2.5em;
    .btn-primary {
      background-color: var(--primary);
      border-color: transparent;
      width: 100%;
      margin-top: 1.2em;
      transition: 0.3s ease-in background-color;
      border-radius: 24px;
      font-size: 14px;
      padding: 0.5rem 4.2rem;
      color: var(--primary-contrast);
      &:hover {
        background-color: var(--primary-darker);
      }
    }
  }
  .close {
    // margin-top: -1em !important;
  }
  .content {
    ol {
      list-style: none;
      counter-reset: giftano-counter;
    }
    li {
      counter-increment: giftano-counter;
      margin: 0.25rem;
      position: relative;
    }
    li::before {
      content: counter(giftano-counter);
      background-color: var(--primary);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      color: white;
      text-align: center;
      margin-right: 0.5rem;
      position: absolute;
      font-size: 18px;
      left: -3rem;
      top: 0.5rem;
    }
    li:not(:last-child):after {
      content: '';
      height: 78%;
      width: 2px;
      background-color: var(--primary);
      position: absolute;
      left: -2rem;
      top: 40px;
    }
    .list-title {
      font-size: 20px;
      line-height: 36px;
      font-weight: bold;
    }
    .list-description {
      font-size: 12px;
      line-height: 18px;
      color: black;
      opacity: 0.45;
    }
  }
  .form-control {
    border-radius: 0;
    background-color: #f1f1f1;
    font-size: 16px;
    padding: 1.2em;
    border-color: transparent;
  }
}

.nav {
  &-tabs {
    justify-content: space-around;
    border-bottom: none;
    margin-bottom: 1em;
    .nav-link {
      width: 48%;
      text-align: center;
      cursor: pointer;
      border-radius: 2em;
      border: 1px solid var(--primary);
      background-color: #f7f7f7;
      color: var(--primary) !important;
      &.active {
        border: 1px solid var(--primary-darker);
        background-color: white;
        color: var(--primary-darker) !important;
      }
      &:hover {
        border: 1px solid var(--primary-darker);
        background-color: white;
        color: var(--primary-darker) !important;
      }
    }
  }
  &-item {
    width: 45%;
  }
}

@media only screen and (max-width: 767px) {
  .nav {
    &-item {
      width: 90%;
      margin-bottom: 1em;
    }
  }
  .modal-help {
    .modal-header button.close {
      // margin-top: -40px !important;
    }
  }
}

.panel-heading {
  background-color: transparent;
  border-color: transparent !important;
  border-bottom: none !important;
  border-bottom-color: transparent !important;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}

.panel-heading .panel-title:after {
  content: url('https://api.iconify.design/mdi:plus-circle-outline.svg?color=%2302AFA0&height=24');
  vertical-align: -0.125em;
  position: absolute;
  right: 0;
  top: 9px;
}

.panel-title {
  color: var(--primary);
  position: relative;
  &:hover {
    color: var(--primary-darker);
  }
}
.panel-title {
  width: 100% !important;
  display: block !important;
  line-height: 1.5em;
  text-align: left;
  padding-left: 0;
  padding-right: 2em;
}

.panel {
  border: none;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid #eee;
}

.panel-body {
  border-top: none !important;
  border-bottom-color: #ddd;
  padding-top: 0.6em;
  padding-bottom: 1.2em;
  color: #606470;
  font-size: 16px;
}
