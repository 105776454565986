.info-page {
  @include MyNavbar(128px);
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
  .giftano-card {
    position: relative;
    padding: 32px;
    max-width: 360px;
    margin: 0px auto;
    .giftano-card-content {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      .card-type {
        top: 100px;
        position: absolute;
        width: 150px;
        left: 61px;
        color: #fff;
      }
      .card-balance {
        top: 100px;
        position: absolute;
        width: 100px;
        right: 50px;
        color: #fff;
        text-align: right;
      }
      .card-name {
        top: 120px;
        position: absolute;
        width: 200px;
        left: 61px;
        color: #fff;
        font-size: 14px;
      }
      .card-expiry-date {
        top: 160px;
        position: absolute;
        width: 200px;
        left: 61px;
        color: #fff;
        font-size: 14px;
      }
      .card-notes {
        color: var(--gf-dark-violet-200);
        top: 180px;
        position: absolute;
        width: 200px;
        font-size: 14px;
        left: 61px;
        font-weight: bold;
        letter-spacing: 1px;
      }

      .qr {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
      }
      .serial {
        text-align: center;
        color: #fff;
        font-size: 18px;
        width: 100%;
        letter-spacing: 1px;
      }
      .pin {
        text-align: center;
        color: #fff;
        font-size: 18px;
        width: 100%;
        letter-spacing: 1px;
      }
    }
  }

  .list-group-item {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid var(--gf-neutral-200);
    border-radius: 0;
    color: var(--gf-neutral-500, #000);
    &:first-child {
      border-top: 1px solid var(--gf-neutral-200);
    }
  }

  .card-form-email {
    background-color: #9ad1cc;
    .card-body {
      padding: 2.6em 3.3em;
    }
  }
  .form-control {
    border-radius: var(--gf-border-radius-m);
    background-color: var(--gf-neutral-100);
    font-size: 16px;
    padding: 1.2em;
    border-color: transparent;
  }
}
