.how-to-redeem-page {
  @include MyNavbar(64px);

  .btn-how-it-work {
    background-color: var(--primary-contrast);
    color: var(--primary);
    border-color: var(--primary);
  }

  .how-to-redeem__banner {
    position: relative;
    margin-bottom: 50px;

    .btn-back {
      left: 2.5em;
      top: 6em;
      background: var(--gf-primary-50);
      height: 30px;
      width: 30px;
      border-radius: 100%;
      opacity: 0.5;
      padding: 0;
      position: absolute;

      &:hover {
        color: var(--primary);
      }
    }

    img {
      width: 100%;
      z-index: -100;
    }
  }
  .title {
    @include font-32-bold;
    color: black;
    letter-spacing: 2%;
  }
  .description {
    @include font-18-light;
    color: black;
    letter-spacing: 2%;
    li {
      @include font-18-light;

      letter-spacing: 1px;
      margin-bottom: 15px;
    }
  }
  .step-2 {
    background-color: var(--gf-primary-50);
    margin-top: 1em;
    padding-top: 2em;
    padding-bottom: 2em;
    position: relative;
    &:before {
      display: block;
      content: '';
      z-index: 0;
      top: 0;
      background-color: var(--gf-primary-50);
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }
  .step-3 {
    background-color: var(--gf-dark-violet-300);

    margin-bottom: 1em;
    // padding-top: 1em;
    // padding-bottom: 1em;
    position: relative;
    &:before {
      display: block;
      content: '';
      z-index: 0;
      top: 0;
      background-color: var(--gf-dark-violet-300);
      width: 100vw;
      height: 100%;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }
}

@media screen and (max-width: 767px) {
}
@media screen and (max-width: 1023px) {
  .landing__icon {
    height: 75px;
  }
  .btn-landing__secondary {
    display: none;
  }
  .landing-image {
    max-width: 80% !important;
    margin: 2em auto;
    display: block;
  }
  .step-3 {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
