.breadcrumb {
  background-color: transparent;
  padding-left: 0;
  .breadcrumb-item {
    &.active {
      color: var(--primary);
    }
    a {
      color: #000;
    }
  }
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-right: 1px solid var(--gf-neutral-200);
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-left: 1px solid var(--gf-neutral-200);
}
