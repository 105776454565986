.landing {
  &__icon {
    width: auto;
    height: 100px;
  }
  &__vertical-centered {
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  &__titling {
    display: flex;
    align-self: flex-start;
    align-items: center;
  }
  &__banner {
    min-height: 378px;
    object-fit: cover;
  }
  .btn-back {
    left: 2.5em;
    top: 6em;
    background: var(--gf-primary-50);
    height: 30px;
    width: 30px;
    border-radius: 100%;
    opacity: 0.5;
    padding: 0;
    position: absolute;

    &:hover {
      color: var(--primary);
    }
  }

  img {
    width: 100%;
    z-index: -100;
  }
}

.btn-back {
  left: 2.5em;
  top: 6em;
  background: var(--gf-primary-50);
  height: 30px;
  width: 30px;
  border-radius: 100%;
  opacity: 0.5;
  padding: 0;
  position: absolute;

  &:hover {
    color: var(--primary);
  }
}

.btn-landing__secondary {
  background-color: transparent;
  color: var(--gf-soft-orange-400);
  text-decoration: underline;
  @include font-16-bold;
  padding-left: 0;
  text-underline-offset: 4px;
}

@media screen and (max-width: 767px) {
  .floating-text {
    font-size: 24px;
  }

  .landing__banner {
    min-height: 280px;
  }
}
