.page-perks {
  @include MyNavbar(128px);
  .redemption-navbar {
    margin-bottom: 1em;
  }
  .title {
    margin-top: 1em;
  }
  .box-list {
    row-gap: 2em;
    padding-bottom: 7rem;
    .box-perk {
      cursor: pointer;
      .card-body {
        height: 135px;
      }
      .img-perk {
        max-height: 222px;
        object-fit: cover;
      }
      .title-perk {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      .merchant-description {
        margin-bottom: 10px;
        gap: 10px;
        .merchant-logo {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #fda29b;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          font-weight: 700;
          color: white;
        }
        .merchant-name {
          font-size: 14px;
          font-weight: 700;
          color: $primary-color;
        }
      }
    }
  }
  .short-description {
    height: 40px;
    font-size: 14px;
  }
}
.modal-perk-detail {
  text-align: center;
  .img {
    width: 100%;
    max-height: 430px;
    object-fit: cover;
    border-radius: 4px;
  }
  .merchant-description {
    gap: 20px;
    margin-top: 20px;
  }
  .merchant-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fda29b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: white;
  }
  .merchant-name {
    color: $primary-color;
    font-size: 14px;
    font-weight: 700;
  }
  .content {
    margin: 20px 20px 30px 20px;
    font-size: 14px;
    .title-content {
      font-weight: 700;
      margin-bottom: 20px;
    }
    .description {
      margin-bottom: 20px;
      p,
      ol,
      ul,
      dl {
        margin: 0 !important;
      }
    }
    .cta-content {
      .url {
        color: $primary-color;
        text-decoration: underline;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      a {
        gap: 10px;
        button {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      border: 1px solid #c4c4c4;
      padding: 10px 10px 10px 20px;
      border-radius: 60px;
    }
  }
  .modal-dialog {
    max-width: 547px;
  }
  .modal-header {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
