.modal {
  &-terms {
    .modal-header {
      padding: 0;
      .close {
        z-index: 30;
        & > span:first-child {
          font-size: 30px;
        }
      }
    }
  }
  &-redemption {
    .modal-header {
      padding: 0;
      .close {
        z-index: 30;
        & > span:first-child {
          font-size: 30px;
        }
      }
    }
  }
  &-how-to-redeem {
    .modal-dialog {
      max-width: 630px;
    }

    .modal-header {
      padding: 0;
      .close {
        z-index: 30;
        & > span:first-child {
          font-size: 30px;
        }
      }
    }
    .modal-body {
      padding-bottom: 3em;
    }
    .title {
      @include font-24-bold;
      color: black;
    }
    .description {
      @include font-18-light;
      color: black;
    }
    .btn-modal-cta {
      display: inline-block;
      width: 47%;
      padding: 10px 15px !important;
    }
  }
  &-confirm {
    .modal-header {
      .close {
        z-index: 30;
        & > span:first-child {
          font-size: 30px;
        }
      }
    }
    .modal-content {
      .icon {
        width: 70px;
        height: 70px;
      }
    }
    .title {
      @include font-24-bold;
      color: black;
    }
    .modal-title {
      h4 {
        font-weight: 700;
        font-size: 18px;
        color: black;
        line-height: 1.35;
        letter-spacing: 1px;
      }
    }
    .modal-body {
      padding: 15px;
      img {
        height: 45px;
        position: absolute;
        width: auto;
        object-fit: contain;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .description {
      @include font-18-light;
      color: #5c5c5c;
      letter-spacing: normal;
    }
    .btn-modal-cta {
      display: inline-block;
      width: 47%;
      padding: 10px 15px !important;
    }
    .btn-invert {
      background-color: white !important;
      color: var(--primary) !important;
      border: 1px solid var(--primary) !important;
    }
  }
  &-confirmation__action {
    gap: 10px;
    display: flex;
    justify-content: center;
    button {
      min-width: 40% !important;
      width: 40% !important;
      padding: 0.5rem 0 !important;
    }
  }
  &-merchant-info {
    .title {
      font-size: 23px;
      font-weight: 700;
      // margin-top: 20px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 700;
    }
    h4.subtitle {
      color: var(--gf-neutral-400);
    }
    .description {
      font-size: 16px;
      color: var(--gf-neutral-400);
    }
  }
  &-merchant-carousel {
    .modal-header {
      padding: 0;
      .close {
        z-index: 30;
        & > span:first-child {
          font-size: 30px;
        }
      }
    }
    // margin-top: 1em;
    border-radius: var(--gf-border-radius-m);
    overflow: hidden;
  }
  &-gift-box {
    .carousel__content {
      padding: 1em 2.4em 0 2.4em;
    }
  }
}

@media screen and (max-width: 767px) {
  .modal {
    padding-top: env(safe-area-inset-top) !important;
    &-gift-box {
      .modal-dialog {
        max-width: 80%;
        margin: 0 auto;
      }

      .carousel button.control-arrow {
        width: 30px;
        height: 30px;
        padding: 0 !important;
        background: transparent !important;
      }
    }
    &-merchant-carousel {
      margin-bottom: 1em;
    }
    &-confirm {
      h4 {
        font-size: 16px !important;
      }
      .description {
        font-size: 12px !important;
      }
    }
    .btn-modal-cta {
      width: 45%;
    }
  }
}
