/* custom specific style for page */
.passcode-page {
  background: radial-gradient(
    309.45% 136% at 50% -41.94%,
    #eae9eb 12.05%,
    var(--primary) 92.58%
  );
  .card-redeem {
    max-width: 580px;
    margin: auto;
    padding: 16px 2.4em;
    box-shadow: 0px 8px 16px 2px rgba($color: #333333, $alpha: 0.16);
    font-weight: 700;
    border-radius: 22px;
    border: none;
    .logo {
      width: 200px;
      margin: 20px auto;
    }
    h3 {
      color: var(--gf-primary-300);
      text-align: center;
      font-family: 'lato';
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .form-group {
      background-color: #f1f1f1;
      padding: 16px 24px;
      border-radius: 8px;

      .form-label {
        text-align: center;
        color: #828282;
        margin-bottom: 16px;
      }
      .form-control {
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid #c4c4c4;
        border-radius: 0;
        margin-bottom: 16px;
        color: black;
        padding-left: 0;
        padding-right: 0;
      }
      /* remove input type number spin button */
      input[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
      }
      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* end remove spin button */
      .form-control:focus {
        border-bottom-color: var(--primary);
        outline: 0;
        box-shadow: none;
      }
      .giftano-card-number {
        font-size: 18px !important;
        letter-spacing: 1px;
        color: #5c5c5c;
        padding: 0px;
      }
      .col-giftano-card-number {
        width: 5%;
      }
    }
    button {
      border-radius: 24px;
      background-color: var(--primary);
      border-color: var(--primary);
      width: 100%;
      margin: 0 auto;
      padding: 12px;
    }
    .btn-scan {
      background: white;
      color: var(--primary);
      // padding: 5px;
    }
    .btn-scan:hover {
      color: white;
    }
    .or-divider {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;

      .line {
        border-bottom: 1px solid #5c5c5c;
        width: 20%;
        height: 14px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
    .recaptcha-text {
      text-align: center;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 16px;
      margin-bottom: 8px;
      a {
        color: var(--primary);
      }
    }
  }

  .version {
    color: #9ad1cc;
    font-size: 14px;
    font-weight: 500;
  }

  // @media (max-width: 376px) {
  //   .card-redeem {
  //     margin-top: -60px;
  //   }
  // }

  @media (max-width: 580px) {
    .recaptcha-text {
      position: relative !important;
    }
  }

  @media (max-width: 1023px) {
    .card-redeem .form-group .form-label {
      font-size: 16px;
      color: var(--gf-neutral-300);
      font-weight: 500;
    }

    .card-redeem .form-group {
      padding: 15px;
    }

    .card-redeem {
      padding: 16px;
    }
    .version {
      color: #9ad1cc;
      font-size: 10px;
      font-weight: 500;
    }
    .recaptcha-text {
      position: absolute;
      bottom: 0;
      font-size: 12px;
      text-align: center;
      color: white;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 10px 40px;
      a {
        color: var(--gf-dark-violet-300);
      }
    }
  }

  @media (min-width: 1024px) {
    .recaptcha-text {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 30px;
      width: 500px;
      text-align: center;
      color: white;
      a {
        color: var(--gf-dark-violet-300);
      }
    }
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
