.page-merchant {
  @include MyNavbar(128px);

  .card-skeleton {
    display: block;
  }
  .card-skeleton-mobile {
    display: none;
  }
  .subtitle {
    color: #000 !important;
    font-weight: 700 !important;
  }
  .rectangle-header {
    background-color: var(--primary);
    height: 128px;
  }
  .merchant-images {
    border-radius: 8px !important;
  }

  .merchant__buy {
    display: none;
  }

  .logo {
    margin-top: 32px;
    margin-bottom: 8px;
    height: 135px;
    width: auto;
    max-height: none;
    font-size: 18px;
    line-height: 28px;
  }
  .carousel-inner {
    border-radius: 8px;
  }
  .listing-price {
    font-weight: 700;
  }
  .merchant-product__list {
    margin-top: 10px;
    // margin-bottom: 50px;
    .card-total {
      &:nth-child(n + 2) {
        border-bottom: 1px solid var(--gf-neutral-200) !important;
        border-radius: 0 !important;
      }
      &:last-child {
        border-bottom: none !important;
      }
    }
    .card-title {
      background-color: var(--gf-primary-50);
      padding: 8px 15px;
      border-radius: 8px 8px 0 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 135%;
      letter-spacing: 1px;
    }
    .cart {
      @include card-shading;
      // margin-top: 28px;
      &__key {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--gf-neutral-500);
        // line-height: 48px;
      }
      &__action {
        margin: 20px auto 5px;
        // padding: 1rem 3rem;
        padding: 13px 20px;
        display: block;
        border-radius: var(--gf-border-radius-l);
        min-width: 50%;
      }
    }
  }
  .merchant-name {
    @include font-41-bold;
  }
  .merchant-name-long {
    font-size: 1.5em !important;
  }
  .merchant-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    /* or 22px */

    text-align: left;
    letter-spacing: 0.9px;
    color: var(--gf-neutral-400);
    & + .btn-link {
      padding: 0;
      margin-bottom: 1em;
      color: var(--primary);
      font-weight: 500;
    }
  }
  .merchant-about {
    margin-bottom: 30px;
  }
  .voucher-card {
    border-radius: 8px !important;
    background-color: transparent;
    border: none;
    .card-title {
      // background-color: var(--gf-light-gray-300);
      // padding: 20px 15px;
      background-color: var(--gf-primary-50);
      padding: 8px 15px;
      border-radius: 8px 8px 0 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 135%;
      letter-spacing: 1px;
      text-align: center;
      // border-radius: var(--gf-border-radius-m);
    }
  }

  .voucher-list {
    border-radius: 8px !important;

    img {
      height: 80px;
      object-fit: contain;
      padding-right: 5px;
      // border-radius: var(--gf-border-radius-m);
      border-radius: 16px;
      overflow: hidden;
    }
  }
  .voucher-name {
    @include font-16-bold;
    margin-bottom: 4px;
  }
  .voucher-desc {
    color: var(--gf-neutral-400);
    margin-top: 0px;
    @include font-14-light;
    position: relative;
    margin-bottom: 0px;
  }
  .voucher-sum {
    color: $giftano-orange;
    font-weight: bold;
    font-size: 18px;
    line-height: 48px;
  }

  .list-group {
    &-item {
      padding: 15px;
      box-shadow: var(--gf-shadow-thin);
      border-radius: var(--gf-border-radius-m);
    }
  }

  .clamped {
    --max-lines: 3;
    position: relative;
    // max-height: $line-height;
    height: 67px;
    overflow: hidden;
    padding-right: 1rem; /* space for ellipsis */
    &:before {
      position: absolute;
      content: '';
      inset-block-end: 0; /* "bottom" */
      inset-inline-end: 0; /* "right" */
    }
    &:after {
      content: '...';
      position: absolute;
      inset-inline-end: 0;
      width: 7.1rem;
      height: 24px;
      padding-bottom: 0;
      background: white;
      bottom: 0;
      right: 0;
    }
  }
  .clamp-lines__button {
    @include clamp-line;
  }
  .voucher-toggle {
    position: absolute;
    right: 20px;
    bottom: 19px;
    cursor: pointer;
    color: $primary-color;
  }
  .voucher-info {
    padding-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .voucher-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .voucher-price {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--gf-warning-300);
  }
  .voucher-qty {
    border: 1px solid var(--gf-neutral-200);
    border-radius: 30px;
    background-color: var(--gf-neutral-100);
    // margin-top: 2em;
    width: 100%;
    justify-content: center;
    .btn {
      background-color: transparent;
      // border-color: var(--gf-neutral-200);
      padding: 0.375rem 1rem;
    }
    .form-control {
      border: none;
      background-color: var(--gf-neutral-100);
    }
    .form-control:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  .card-total {
    .card-body {
      padding: 8px;
      // padding: 10px 15px;
    }
    // background-color: #e0e0e0;
    &.card-has-cart {
      .card-body {
        padding-top: 0;
        padding-bottom: 0;
        p {
          color: #6e6e6e;
        }
      }
    }
    &.card-has-balance {
      background-color: var(--gf-neutral-100);
    }

    .listing-name,
    .listing-counter {
      font-weight: 400;
      font-size: 12px;
      line-height: 135%;
      /* or 16px */

      letter-spacing: 1px;
      color: #000 !important;
    }

    &.merchant__buy {
      padding-bottom: 0;
    }

    .merchant__amount-topup {
      position: relative;
      &:before {
        // content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: #f3f3f3;
      }
    }
    border: none;
    border-radius: 8px;
    // padding-top: 10px;
    padding-bottom: 10px;
    // margin-top: 20px;

    h4 {
      color: var(--gf-neutral-500);
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      margin: 0;
    }
    .card-total__nominal {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: var(--gf-neutral-500);
    }
    .card-total__nominal--small {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.3;
      color: var(--gf-neutral-500);
    }
    .btn {
      background-color: var(--primary);
      color: var(--primary-contrast);
      border: none;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.2;
      transition: 0.3s ease-in background-color;
      &:hover {
        background-color: $primary-dark;
      }
    }
    .btn-alt {
      background-color: #fc9e4f;
      @media screen and (max-width: 1023px) {
        margin-top: 2em;
        font-size: 14px;
      }
    }
  }

  // product page
  .product-featured {
    padding: 0;
    box-shadow: none;
    .list-image {
      /* Hide scrollbar for Chrome, Safari and Opera */
      & > .row::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      & > .row {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      padding-right: 10px;
      .merchant-images {
        padding-right: 0;
      }
      img {
        padding-right: 0;
      }
      .inactive-image {
        opacity: 0.4;
      }
    }
    img {
      height: auto !important;
      width: 100%;
      border-radius: 4px !important;
    }
    .merchant-container {
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        /* or 22px */

        text-align: left;
        letter-spacing: 0.9px;
      }
    }
    .brand-name {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.2;
      // margin-top: 30px;
      margin-bottom: 25px;
    }
    .voucher-name {
      font-weight: 700;
      font-size: 32px;
      line-height: 135%;
      margin-bottom: 30px;
    }
    .voucher-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      /* or 22px */

      text-align: left;
      letter-spacing: 0.9px;
    }
    .voucher-actions {
      &__bottom {
        align-items: center;
        h5 {
          font-weight: 400;
          font-size: 16px;
          line-height: 135%;
          letter-spacing: 1px;
        }
      }
    }
  }

  .product-listing {
    img {
      height: 100px;
    }
    .brand-name {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.2;
      // margin-top: 30px;
      margin-bottom: 25px;
    }
    .voucher-name {
      font-weight: 700;
      font-size: 20px;
      line-height: 135%;
      letter-spacing: 1.4px;
      color: black;
      margin-top: 10px;
    }
    .voucher-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 135%;
      color: black;
      letter-spacing: 0.9px;
      text-align: left;
    }
  }

  .merchant-misc {
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 38px;
      /* or 22px */

      text-align: left;
      letter-spacing: 1px;
      color: #000;
    }
    &__view-all {
      font-weight: 700;
      font-size: 16px;
      line-height: 135%;
      /* identical to box height, or 22px */

      text-align: justify;
      letter-spacing: 1px;
      color: var(--primary);
    }
    img {
      object-fit: cover;
      min-height: 160px;
      width: 100%;
    }
    .card {
      box-shadow: 0px 5.96096px 11.9219px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      border: none;
      overflow: hidden;
      margin-bottom: 1em;

      .card-title {
        font-weight: 700;
        font-size: 13px;
        letter-spacing: 0.8px;
        line-height: 1.35;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: 35px;
        min-height: 2.8em;
      }
      .card-body {
        p {
          font-weight: 700;
          font-size: 24px;
          line-height: 1.35;
          /* or 17px */

          text-align: left;
          letter-spacing: 0.8px;
          color: var(--gf-warning-300);
        }
      }
      .btn-block {
        width: 50%;
      }
    }
  }

  .Hprice {
    .voucher-price--merchant {
      display: none !important;
    }
  }

  // end product page specific

  // merchant page

  .voucher-actions {
    align-items: center;
  }

  .merchant-listing-actions {
    .voucher-desc {
      font-weight: 400;
      line-height: 135%;
      color: black;
      letter-spacing: 0.9px;
      text-align: left;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 58px;
    }
  }

  // end merchant page

  .merchant-information {
    padding-top: 18px;
    padding-bottom: 18px;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 12px;
      margin-top: 15px;
    }
    &__desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 135%;
      margin-top: 15px;
      text-align: left;
      letter-spacing: 1px;
    }
    img {
      cursor: pointer;
    }
  }

  .footnote {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 16px;
    margin-bottom: 75px;
  }
  .merchant-container {
    // height: calc(100vh - 256px);
    // overflow: scroll;
    .breadcrumb {
      margin-bottom: 0;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      letter-spacing: 0.1px;

      color: #000000;
    }
  }

  .gift-box {
    // navigation carousel next and prev gift
    .btn-gift-control {
      margin-top: 30px;
      color: var(--primary);
    }
    // inside carousel item
    .carousel-inner {
      border-radius: 0;
    }
    .gift-carousel-item {
      text-align: left;
      .gift-image {
        border-radius: 4px;
        margin-bottom: 10px;
      }
      .gift-merchant-name {
        color: var(--gf-soft-orange-400, '#CA7E3F');
        padding: 0;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
      }
      .gift-name {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 0;
      }
      .hr {
        margin-top: 0;
        margin-bottom: 25px;
      }
      p {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        margin-bottom: 0;
        color: var(--gf-neutral-400);
      }
      .merchant-desc-clamp-btn {
        color: var(--gf-primary-400);
        padding: 0;
      }
      .btn-select-gift {
        margin-top: 25px;
        margin-bottom: 0;
        height: 50px;
        font-size: 18px;
      }
    }
    .ultimate-gift-merchant {
      h4 {
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
      }
      .merchant-about {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        margin-bottom: 20px;
        color: var(--gf-neutral-400);
      }
    }
    .other-gifts {
      @include full-width;
      padding-bottom: 30px;
      background: var(--gf-dark-violet-200, '#443A4E');
      padding-top: 25px;
      .title {
        margin-top: 25px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .gift-container {
        overflow-x: scroll;
        margin-top: 15px;
        padding-left: 15px;
        padding-bottom: 10px;
        scroll-behavior: smooth;
        position: relative;
        .gift-scroller {
          width: max-content;
          .gift-box {
            width: 260px;
            display: inline-block;
            margin-right: 20px;
            padding: 0;
            border: 0;
            border-radius: var(--gf-border-radius-l);
            .card {
              border: 0;
              .card-body {
                padding: 10px 15px;
              }
            }
            .gift-image {
              height: 130px;
            }
            .merchant-name {
              color: var(--gf-soft-orange-400, '#CA7E3F');
              margin-top: 0;
              margin-bottom: 5px;
              font-size: 16px;
            }
            .gift-name {
              font-size: 16px;
              font-weight: 700;
              min-height: 42px;
            }
            .gift-desc {
              font-size: 14px;
              font-weight: 500;
              height: 57px;
            }
            .btn-view {
              padding: 0px;
              font-size: 14px;
              line-height: 20px;
              height: 37px;
              width: 120px;
            }
          }
        }
      }
    }
  }
  .carousel-root {
    margin-bottom: 1em;
  }
}

.modal-merchant-info {
  .contacts {
    margin-bottom: 30px;
    p {
      margin-bottom: 0px;
    }
  }
}

.modal-confirm .modal-title p {
  font-size: 16px;
  margin-top: 3px;
}

.modal-shipment {
  .input-group-text,
  .form-control {
    background-color: #f5f5f5;
    border: none;
  }
  .btn-send-gift {
    background-color: var(--primary);
    border-radius: 24px;
    color: #f1f1f1;
    border: none;
    font-size: 14px;
    line-height: 22px;
    padding-left: 24px;
    padding-right: 24px;
    height: 48px;
    margin-top: 32px;
  }
}

@media (min-width: 1023px) {
  .page-merchant .voucher-list img {
    height: 125px;
    // border-radius: 16px 0 0 16px;
    border-radius: 16px;
    // position: absolute;
    // left: -15px;
    // top: -15px;
  }

  .page-merchant .list-group-item {
    min-height: 125px;
  }
}

@media only screen and (max-width: 767px) {
  .page-merchant {
    .carousel-root {
      margin-bottom: 0;
    }
    .card-skeleton {
      display: none;
    }
    .card-skeleton-mobile {
      display: block;
    }
    .breadcrumb .breadcrumb-item.active {
      max-width: 200px;
      height: 20px;
      -webkit-line-clamp: 1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .rectangle-header {
      height: 0 !important;
    }
    // .redemption-navbar {
    //   margin-top: 60px;
    // }
    .logo {
      height: 50px;
    }
    .merchant-container {
      padding-bottom: 100px;
      nav {
        position: relative;
        margin-bottom: 10px;
        &:after {
          position: absolute;
          content: '';
          height: 1px;
          width: 100%;
          background-color: var(--gf-neutral-200);
        }
      }
      p {
        display: block !important;
        &.voucher-desc {
          display: none !important;
        }
      }
    }

    .merchant-name {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      /* or 34px */

      letter-spacing: 1px;
      margin: 15px 0;
    }

    .voucher-price {
      width: 60%;
      margin-bottom: 0;
      font-size: 15px;
      line-height: 18px;
      font-weight: 700;
      color: var(--gf-warning-300);
    }
    .voucher-actions {
      // flex-direction: initial;
      align-items: center;
    }
    .voucher-list {
      // box-shadow: var(--gf-shadow-thin) !important;
      // padding: 5px;
      &:last-child {
        margin-bottom: 30px;
      }
      .voucher-price--merchant {
        position: absolute;
        bottom: 0;
        left: 15px;
        font-size: 14px;
        margin-bottom: 0;
        color: var(--gf-warning-300);
      }
      .list-group-item {
        // border-bottom: 1px solid var(--gf-neutral-200);
        // border-radius: 0;
        background: #ffffff;
        border: 1px solid #f9f9f9;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        // padding-bottom: 10px;
        // height: 80px;
      }
      .list-group-item:last-child {
        border: none;
      }
      img {
        border-radius: 8px;
        // height: 65px;
      }
    }
    .voucher {
      &-info {
        padding-left: 0;
      }
      &-qty {
        margin-top: 0;
        transform: scale(0.75);
        .btn {
          padding: 5px 7px;
        }
      }
    }
    // .merchant-name {
    //   font-size: 1.2em;
    // }
    .merchant__amount-topup {
      &:before {
        width: 100vw;
        left: -35px;
      }
    }
    .card-total {
      &.merchant__buy {
        // position: fixed;
        // bottom: 92px;
        display: block;
        z-index: 30;
        width: 100%;
        background-color: white;
        // box-shadow: var(--gf-shadow-thin) !important;
        left: 0;
        // padding-left: 1em;
        // padding-right: 1em;
      }

      &.card {
        &-has-balance,
        &-has-order,
        &-has-cart {
          &:before {
            display: block;
            // content: '';
            z-index: -1;
            background: white;
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
          }
        }

        &-has-cart {
          .card-title {
            position: relative;
            &:before {
              display: block;
              // content: '';
              z-index: -1;
              background: $dark;
              width: 100vw;
              height: 100%;
              position: absolute;
              top: 0;
              left: 50%;
              right: 50%;
              margin-left: -50vw;
              margin-right: -50vw;
            }
          }
        }
      }
    }
    .merchant-product__list .card-total.card-has-balance {
      border-bottom: 0 !important;
    }
    .list-group {
      &-item {
        position: relative;
        box-shadow: none;
        margin-bottom: 5px !important;
        margin-top: 5px !important;
        &:before {
          background: white;
          // @include full-width-container;
          box-shadow: var(--gf-shadow-thin);
        }
      }
    }
    .merchant-product__list {
      .card-title {
        position: relative;
        z-index: 0;
        &:before {
          // @include full-width-container;
          background-color: var(--gf-light-gray-300);
          z-index: -1 !important;
        }
      }
    }
    .cart {
      position: relative;
      box-shadow: none;
      // margin-bottom: 5px !important;
      margin-top: 5px !important;
      // &:before {
      //   background: white;
      //   @include full-width-container;
      //   box-shadow: var(--gf-shadow-thin);
      // }
      &__container {
        align-items: center;
      }
      &__key {
        font-weight: bold !important;
        color: #000 !important;
      }
      &__action {
        // margin-right: 0 !important;
        margin: 20px 5px 20px 20px !important;
      }
    }
    .footer {
      display: none;
    }
    .merchant-product__list .cart {
      box-shadow: 0px 4px 20px rgba(92, 92, 92, 0.1);
    }
    p.merchant-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      /* or 21px */

      text-align: left;
      letter-spacing: 0.9px;
    }
    .merchant-how-it-work {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        text-align: left;
        letter-spacing: 0.9px;
      }
    }
    // with price
    .Wprice {
      .product-featured {
        box-shadow: none;
        .voucher-actions-multi {
          margin-top: 2em;
          padding-left: 0;
          padding-right: 0;
        }
        .voucher-actions {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          align-items: baseline;
          padding-left: 0;
        }
        .voucher-actions__bottom {
          margin-left: auto;
          margin-right: auto;
          & > div {
            padding-left: 0;
          }
          .voucher-qty {
            transform: scale(0.95);
          }
          .btn-list__product {
            // padding: 6px 25px !important;
            padding: 6px 37% !important;
          }
        }
        .voucher-price {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          text-align: left !important;
        }
        .brand-name {
          font-weight: 400;
          font-size: 16px;
          line-height: 1.2;
          margin-bottom: 8px;
        }
        .voucher-name {
          font-weight: 700;
          font-size: 24px;
          line-height: 135%;
          margin-bottom: 8px;
        }
        .voucher-desc {
          display: block !important;
        }
      }
      .product-listing {
        // padding: 15px 5px;
        // .voucher-name{
        //   padding-left: 5px;
        // }
        .voucher-actions {
          flex-direction: row;
        }
        .voucher-actions-multi {
          justify-content: normal;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 10px;
        }
        .list-image {
          font-size: 12px;
          letter-spacing: 0.9px;
        }
      }
      .merchant-information__desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        text-align: left;
        letter-spacing: 0.9px;
      }
      .voucher-actions {
        .btn-list {
          font-size: 12px !important;
          padding: 5px 15px !important;
          line-height: 1.2;
        }
      }
    }

    // end with price

    // hide price
    .Hprice {
      .product-featured {
        box-shadow: none;
        .voucher-actions-multi {
          margin-top: 2em;
        }
        .voucher-desc {
          display: block !important;
        }
        .btn-select--single {
          font-weight: 700;
          font-size: 19px;
          line-height: 1.3;
          padding: 0.5rem 3.5rem;
        }
      }
      .product-listing {
        min-height: 95px;

        .voucher-actions {
          align-items: end;
          align-self: end;
        }
        .voucher-actions-multi {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          min-height: 78px;
          padding-left: 5px;
        }
      }
      .merchant-information__desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        /* or 21px */

        text-align: left;
        letter-spacing: 0.9px;
      }
      .voucher-actions {
        .btn-list {
          font-size: 12px !important;
          padding: 5px 15px !important;
          line-height: 1.2;
        }
      }
    }
    // end hide price
    // product page
    .product-listing {
      img {
        height: 85px;
        padding: 0;
        position: absolute;
        // top: -5px;
        left: 0;
      }
      .voucher-name {
        font-size: 12px;
        letter-spacing: 0.9px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        min-height: 15px;
        max-height: 38px;
        padding-left: 5px;
      }
    }

    .product-featured {
      .list-image {
        padding-right: 0;
      }
    }
    // end product page
    // merchant page
    .merchant-page-listing {
      .merchant-listing-actions {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        .listing-cta-container {
          min-height: 55px;
        }
        .listing-cta {
          display: flex;
          justify-content: flex-end;
          .btn {
            font-size: 12px !important;
            padding: 5px 10px !important;
            align-self: end;
          }
        }
        .listing-price {
          font-size: 13px;
          margin-bottom: 0;
          text-align: left !important;
        }
      }
      .voucher-list {
        .list-group-item {
          // height: 80px;
          height: auto;
        }
        img {
          // height: 65px;
          height: auto;
          margin-top: 5px;
        }
      }
    }
    .merchant-misc {
      .merchant-misc__title {
        line-height: 1.3;
        letter-spacing: initial;
        text-align: left;
      }
      .merchant-misc__view-all {
        font-size: 14px;
        padding-right: 0;
      }
      .card {
        .card-body {
          padding: 10px;
          p {
            font-size: 15px;
          }
        }
        .btn-block {
          width: 100%;
          width: 100%;
          line-height: 1;
          padding: 8px 10px !important;
        }
      }
      img {
        // object-fit: cover;
        min-height: 85px;
      }
    }
    // end merchant page
  }
}

@media (min-width: 768px) {
  .product-listing {
    .list-image {
      .list-image {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
  .Wprice {
    .product-listing {
      .list-image {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
      }
    }
    .merchant-listing-actions {
      .voucher-price--merchant {
        display: none !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .page-merchant {
    padding-bottom: 60px;
  }
  .rectangle-header {
    height: 0 !important;
  }
  // .redemption-navbar {
  //   padding-top: 65px;
  //   height: 60px;
  // }
  .merchant-name {
    margin: 20px 0;
  }

  .page-merchant {
    .voucher-list img {
      border-radius: 8px;
    }
    .list-group-item {
      min-height: 125px;
    }
    .merchant-misc img {
      min-height: 95px;
    }
    .merchant-misc .card .card-body .btn {
      width: 100%;
    }
    .merchant-misc .card-body p {
      font-size: 16px !important;
    }
    .voucher-actions {
      margin-left: -10px;
    }
    .product-featured {
      .list-image {
        margin-bottom: 15px;
        padding-right: 0;
      }
    }
  }
}
