.my-navbar {
  .nav {
    &-link {
      text-align: center;
    }
  }
  .navbar-nav > div {
    width: 50%;
    display: flex;
  }
  .navbar__right {
    justify-content: flex-end;
    align-items: center;
  }
  .nav-balance-title {
    display: flex;
    text-align: center;
    align-items: center;
    background-color: var(--gf-light-gray-200);
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 14px;
    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }

  .navbar-nav {
    width: 100%;
  }

  .collapsing {
    transition: height none !important;
  }
  .navbar-toggler {
    svg {
      color: var(--giftano-card-redemption-nav-font-color) !important;
    }
  }
}
