.page-getstarted {
  // background-image: url('../assets/images/get-started-bg.png');
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  @include MyNavbar(64px);
  &.gift-card {
    .qrcd-gift__clipb {
      color: #2f243a;
    }
  }

  .row-alert {
    margin-top: 24px;
    @include media-breakpoint-down(md) {
      margin-top: 32px;
      margin-bottom: 24px;
      .alert {
        margin-bottom: 0px;
      }
    }
    @include media-breakpoint-down(sm) {
      margin-top: 8px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  .product-box-container {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .hero {
    &:before {
      content: '';
      position: absolute;
      width: 450px;
      height: 80%;
      background-color: var(--primary);
      border-radius: 4px;
    }
    margin-top: 40px;
    height: 500px;
    @include media-breakpoint-down(sm) {
      height: 360px;
      margin-top: -24px;
    }
    position: relative;
    .expired-label {
      position: absolute;
      z-index: 10;
      top: 38%;
      transform: rotate(-29deg);
      left: 0;
      right: 0;
      width: 46%;
      margin: auto;
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      align-items: center;
      text-align: center;
      justify-content: center;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      background: #f79009;
      border-radius: 4px;
      display: flex;
      padding: 9px 17px;
      box-shadow: -5px 5px #874d00;
      &::after {
        content: ' ';
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        border: 2px solid white;
        border-radius: 1px;
        position: absolute;
        left: 5px;
      }
    }
    .hero-bg {
      width: 420px;
      height: 420px;
      border-radius: 4px;
      box-shadow: 0px 20px 10px -10px rgba(0, 0, 0, 0.2);
      background-color: var(--gift-box-title-color);
      position: absolute;
    }
    .hero-image {
      width: 440px;
      height: 420px;
      object-fit: cover;
      position: absolute;
      left: 25px;
      top: 16px;
      border-radius: 4px;
      box-shadow: 0px 20px 10px -10px rgba(0, 0, 0, 0.2);
    }
    .plane {
      width: 124px;
      left: 28%;
      bottom: -59px;
      position: absolute;
      transform: rotateZ(56deg);
    }
    .highlight {
      position: absolute;
      z-index: 1;
    }
    .gift-card {
      width: 200px;
      position: relative;
      left: 400px;
      top: 100px;
      background-size: cover;
      height: 136px;
      transform: rotate(3deg);
      &:after {
        content: '';
        background: url(https://static-cdn.giftano.com/assets/gift-card/highlight.png)
          no-repeat;
        position: absolute;
        height: 50px;
        width: 50px;
        left: 90%;
        top: -20px;
        background-size: cover;
      }
      .qrcd-gift__clipb {
        color: #2f243a;
      }
    }
    .gift-box {
      .hero-image-container {
        flex: 0 0 50%;
        max-width: 50%;
      }
      transform: none;
      bottom: 0;
      left: 50%;
      top: 60%;
      &:after {
        left: 75%;
        top: 0;
      }
      img {
        width: 100%;
      }
    }
    .member-name {
      color: #ffffff;
      font-size: 12px;
      top: 45px;
      left: 20px;
      position: absolute;
    }
    .member-balance {
      color: #ffffff;
      font-size: 12px;
      top: 45px;
      right: 10px;
      position: absolute;
    }
    .member-expired {
      color: #ffffff;
      font-size: 10px;
      position: absolute;
      left: 20px;
      bottom: 20px;
      .expired-label {
        position: absolute;
        z-index: 10;
        top: -53px;
        transform: rotate(-29deg);
        left: 0px;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        background: #f79009;
        border-radius: 4px;
        display: flex;
        padding: 9px 17px;
        box-shadow: -5px 5px #874d00;
        &::after {
          content: ' ';
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          border: 2px solid white;
          border-radius: 1px;
          position: absolute;
          left: 5px;
        }
      }
    }
    .label-not-for-resale {
      color: #000000;
      font-size: 8px;
      position: absolute;
      left: 20px;
      bottom: 10px;
    }
  }
  .hero-title {
    padding-top: 50px;
    @include media-breakpoint-down(sm) {
      .image-title-gift-box {
        width: 100px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    @include media-breakpoint-up(sm) {
      padding-bottom: 50px;
      .image-title-gift-box {
        display: none;
      }
    }
    @include media-breakpoint-only(lg) {
      padding-left: 45px;
    }
    .title {
      font-size: 56px;
      font-weight: 900;
      line-height: 56px;
      margin-bottom: 30px;
      span {
        color: var(--gift-box-title-color);
      }
    }
    h5 {
      color: var(--primary);
      letter-spacing: 1px;
      font-size: 18px;
    }
    .title-gift-box {
      color: var(--gift-box-title-color, #000000);
      font-size: 48px;
      span {
        color: #000000;
      }
    }
    .btn-open-qr {
      padding: 0.5rem 1.5rem;
      &:hover {
        background-color: var(--primary);
        color: var(--gf-neutral-50);
      }
    }
    .btn-env {
      background-color: #e6f4f2;
      border: 1px solid #c1cac8;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      padding: 7px 9px;
      color: #4eaca0;
      position: relative;
      overflow: hidden;

      svg {
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
        margin: auto !important;
        animation: mail-up 0.6s ease-in 0.8s forwards;
        animation-name: mail-up, rotate-mail;
        animation-delay: 0s, 1s;
        animation-duration: 0.6s, 1500ms;
        animation-timing-function: ease-in, linear;
        animation-iteration-count: 1, infinite;
      }
    }

    .mobile-text {
      display: none;
    }

    .get-started-label {
      font-weight: bold;
      margin-top: 36px;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        margin-top: 26px;
        margin-bottom: 8px;
      }
    }

    .btn-get-started {
      letter-spacing: 1px;
      padding-left: 42px;
      padding-right: 42px;
      @media (hover: none) {
        &:focus,
        &:active,
        &:hover {
          background-color: var(--primary) !important;
          border-color: transparent !important;
        }
      }
    }
  }

  .validity {
    font-size: 16px;
    color: var(--gf-neutral-400);
  }
  .qrcd-gift {
    bottom: -3px;
    right: 0;
    padding: 20px;
    width: 413px;
    height: 171px;
    right: -91px;
    background-image: url(../../assets/images/bgqrdark.png);
    background-position: -20px bottom;
    &__serial {
      color: #028c80;
    }
    &__clipb {
      color: white;
    }
    @media (max-width: 576px) {
      right: -71px !important;
    }
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .qrcd-gift2 {
    background-image: url(../../assets/images/bgqr.png) !important;
  }

  .subtitle {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 35px;
    line-height: 20px;
    color: var(--primary-contrast);
  }
  .desc {
    font-size: 18px;
    margin-bottom: 50px;
    line-height: 26px;
    font-weight: 400;
    color: var(--primary-contrast);
    font-style: normal;
    // max-width: 62%;
  }
  .inspired {
    // margin-top: 85px;
    background-color: var(--gf-dark-violet-200);
    padding-top: 60px;
    padding-bottom: 100px;
    @include media-breakpoint-down(sm) {
      padding-bottom: 32px;
    }
    .input--icon {
      margin: 0 !important;
      border-right-color: #ced4da !important;
    }
    .product-box {
      background-color: #ffffff;
      box-shadow: 0px 4px 20px rgba(92, 92, 92, 0.1);
      border-radius: 4px;
      margin-bottom: 15px;
      overflow: hidden;
      .product-image {
        width: 100%;
        height: 130px;
        object-fit: cover;
        transition: all 0.3s;
        transform: scale(1);
        &:hover {
          transform: scale(1.1);
        }
      }
      .product-name {
        padding: 0px 15px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        color: #000;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        height: 40px;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
      }
      .product-merchant {
        padding: 0 15px;
        color: var(--gf-soft-orange-400);
        margin-bottom: 0px;
        margin-top: 10px;
        font-size: 14px;
      }
      .product-description {
        padding: 15px;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        height: 67px;
        -webkit-box-orient: vertical;
        font-size: 14px;
        color: var(--gf-dark-violet-200);
      }
      .product-price {
        padding: 15px;
        font-size: 16px;
        font-weight: 700;
        color: var(--primary);
        margin-bottom: 20px;
      }
      .product-button {
        padding: 6px 25px;
      }
    }
  }

  .find-your-gift {
    text-align: center;
    padding-top: 80px;
    @include media-breakpoint-down(sm) {
      padding-top: 32px;
    }
    .input-group {
      width: 75%;
      margin: 0 auto;
    }
    margin-bottom: 50px;
  }

  .heading-2 {
    font-size: 29px;
    line-height: 35px;
    margin-bottom: 25px;
    font-weight: 700;
  }

  .popular-categories {
    padding-top: 30px;
    .popular-container {
      overflow: scroll;
      margin-top: 15px;
      .popular-scroller {
        width: max-content;
        .popular-box {
          cursor: pointer;
          display: inline-flex;
          width: 260px;
          height: 260px;
          margin-right: 10px;
          background-size: cover;
          justify-content: center;
          span {
            background: #2f243a;
            padding: 15px;
            color: #ffffff;
            font-size: 20px;
            font-weight: 800;
            align-self: center;
          }
        }
      }
    }
  }

  .about {
    min-height: 450px;
    margin-top: 100px;
    @include media-breakpoint-down(sm) {
      margin-top: 0px;
    }
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    .about-heading {
      color: #000000;
      margin: 20px;
      font-size: 32px;
      line-height: 38px;
      font-weight: 900;
      text-align: left;
    }
    // hide about when mobile
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .about-giftbox {
    background-color: #e6f4f2;
    background-image: url('https://static-cdn.giftano.com/assets/gift-card/bg-about.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about-giftcard {
    margin-top: 30px;
    padding-top: 0px;
  }

  .about-giftano {
    .about-content {
      margin-top: 10px;
      padding-right: 24px;
      padding-left: 24px;
      text-align: left;
      font-size: 18px;
      margin-bottom: 30px;
    }
    button {
      max-width: 250px;
      margin-left: 15px;
      // margin: 0 auto;
    }
  }

  .how-it-work {
    margin-bottom: 120px;
    .bordered {
      visibility: hidden;
      & > * {
        visibility: visible;
      }
    }
    h3 {
      font-size: 26px;
      margin-bottom: 50px;
      margin-top: 20px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      min-height: 80px;
      text-align: center;
    }

    img {
      width: auto;
      background-size: contain;
      height: 180px;
      margin: 0 auto;
    }
    .arrow-indicator {
      position: absolute;
      // width: 100px;
      height: auto !important;
      display: block;
      left: 90%;
      top: -35%;
      bottom: 0;
      margin: auto !important;
    }
  }
}
.container-gf-hr {
  padding-bottom: 85px;
  @media (max-width: 1024px) {
    padding-bottom: 170px !important;
  }
  @media (max-width: 992px) {
    padding-bottom: 107px !important;
  }
  @media (max-width: 767px) {
    padding-bottom: 15px !important;
    // padding-bottom: 170px !important;
  }
  @media (max-width: 576px) {
    padding-bottom: 15px !important;
    // padding-bottom: 195px !important;
  }
  &.container-gf-hr2 {
    padding-bottom: 65px !important;
    @media (max-width: 1200px) {
      padding-bottom: 140px !important;
    }
    @media (max-width: 576px) {
      padding-bottom: 195px !important;
    }
    // &.exp-gf {
    //   padding-bottom: 0px !important;
    // }
  }
  // &.exp-gf {
  //   padding-bottom: 85px !important;
  // }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .page-getstarted {
    .desc {
      max-width: 100%;
    }
    .hero {
      &:before {
        width: 350px;
      }
      .hero-title .title {
        font-size: 32px;
        line-height: 1.2;
      }
      .hero-image {
        width: 90%;
        left: 20px;
      }
      .hero-bg {
        width: 100%;
        height: 380px;
        left: -15px;
      }
      .gift-card {
        left: 48% !important;
        top: 60%;
      }
      .plane {
        left: 55px !important;
        bottom: -25px;
        top: auto;
        transform: rotateZ(66deg);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ismerge {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .gift-box.page-getstarted {
    padding-bottom: 80px;
  }
  .page-getstarted {
    .desc {
      font-size: 16px;
      font-weight: 400;
      max-width: 100%;
    }
    .popular-categories .popular-container .popular-scroller {
      width: auto;
      .popular-box {
        width: 47%;
        object-fit: cover;
        height: 170px;
        border-radius: 0;
        display: inline-flex;
        /* flex-wrap: nowrap; */
        margin-right: 5px;
        margin-left: 5px;
        margin-bottom: 10px;
        /* padding-left: 5px; */
        /* padding-right: 5px; */
      }

      .popular-box span {
        font-size: 14px;
      }
    }
    .container-gf-hr2 {
      padding-bottom: 0 !important;
    }
    .qrcd-gift {
      display: none !important;
    }
    .find-your-gift .input-group {
      width: 100%;
    }
    .about {
      &.accent {
        height: auto;
        padding-top: 0;
      }
    }

    .hero-title {
      padding-top: 0;
      position: relative;
      .title {
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        line-height: 20px;
      }
      span {
        font-style: normal;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .btn-env {
        position: absolute;
        z-index: 10;
        right: 10px;
        bottom: -4px;
      }
      .mobile-text {
        position: relative;
      }
      .image-title-gift-box {
        display: none;
        & + h5 {
          display: none;
        }
      }
      .title-gift-box {
        display: none;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }

    .hero {
      &:before {
        content: '';
        position: absolute;
        width: 80%;
        height: 80%;
        background-color: var(--primary);
        border-radius: 4px;
      }
      &-title {
        .title {
          margin-top: 10px;
          margin-bottom: 35px;
        }
      }
      .gift-card {
        // display: none;
        position: absolute;
        left: auto;
        right: 10px;
        bottom: 35px;
        top: auto;
        transform: rotate(0);
      }

      .gift-box {
        right: 0 !important;
        bottom: 65px !important;
      }

      .hero-image {
        left: 15px;
        width: 80%;
        // transform: translateX(-15px);
        // border-radius: 0;
        // height: auto;
        height: 290px;
        box-shadow: 3px 4px 4px 1px rgba(0, 0, 0, 0.2);
      }
      .plane {
        width: 70px;
        right: 0px;
        top: 60px;
        position: absolute;
        transform: rotateZ(189deg) rotateY(180deg);
        left: auto;
        bottom: auto;
      }
      // .hero-bg {
      //   display: none;
      // }
      &-text-container {
        margin-top: -20px;
      }
    }
    .about-giftano {
      margin-bottom: 50px;
      img {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
    }
    .how-it-work {
      margin-bottom: 50px;
      .bordered {
        border: 1px solid rgba(196, 196, 196, 0.5);
        box-shadow: 0px 4px 4px -5px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        visibility: visible;
        padding: 15px 30px;
      }
      h3 {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      &-mobileinfo {
        width: 75% !important;
        margin: auto;
        display: block !important;
        min-height: 0 !important;
        margin-bottom: 15px;
      }
      .arrow-indicator {
        display: none;
      }
      img {
        width: 35%;
        display: inline-block;
        height: auto;
        background-size: cover;
        margin-bottom: 30px;
      }

      p {
        width: 65%;
        display: inline-block;
        vertical-align: top;
        margin-top: 25px;
        text-align: left;
      }
    }
  }

  .product-box {
    &-container {
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 10px;
    }
  }
  .mobile-text {
    display: block !important;
    color: var(--primary);
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .gift-card.page-getstarted .hero .plane {
    right: 0;
    left: 66%;
    transform: rotateZ(360deg);
    bottom: 15%;
  }

  .gift-box {
    .hero-image-container {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .hero-text-container {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
