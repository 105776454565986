//  * setup custom animation

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes flip-env {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  25% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  50% {
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
  }
  99% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
}

@keyframes flip-env {
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  25% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  50% {
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
  99% {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  100% {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
}

@-webkit-keyframes open-env {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    top: -1px;
  }
  50% {
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    top: 2px;
  }
  99% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    top: 1px;
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    top: 2px;
  }
}

@keyframes open-env {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    top: 0px;
  }
  50% {
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    top: 2px;
  }
  99% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    top: 1px;
  }
  100% {
    -webkit-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
    top: 2px;
  }
}

@-webkit-keyframes lift-card-mobile {
  0% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(0px);
    transform: rotateY(180deg) translateY(0%) translateZ(0px);
    opacity: 0;
  }

  50% {
    -webkit-transform: rotateY(180deg) translateY(-120%) translateZ(0px);
    transform: rotateY(180deg) translateY(-120%) translateZ(0px);
    opacity: 0.6;
  }
  99% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(3px);
    transform: rotateY(180deg) translateY(0%) translateZ(3px);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(3px);
    transform: rotateY(180deg) translateY(0%) translateZ(3px);
    opacity: 1;
  }
}

@keyframes lift-card-mobile {
  0% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(0px);
    transform: rotateY(180deg) translateY(0%) translateZ(0px);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotateY(180deg) translateY(-120%) translateZ(0px);
    transform: rotateY(180deg) translateY(-120%) translateZ(0px);
    opacity: 0.6;
  }
  99% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(3px);
    transform: rotateY(180deg) translateY(0%) translateZ(3px);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(3px);
    transform: rotateY(180deg) translateY(0%) translateZ(3px);
    opacity: 1;
  }
}

@-webkit-keyframes lift-card {
  0% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(0px);
    transform: rotateY(180deg) translateY(0%) translateZ(0px);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotateY(180deg) translateY(-110%) translateZ(0px);
    transform: rotateY(180deg) translateY(-110%) translateZ(0px);
    opacity: 0.6;
  }
  99% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(3px);
    transform: rotateY(180deg) translateY(0%) translateZ(3px);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(3px);
    transform: rotateY(180deg) translateY(0%) translateZ(3px);
    opacity: 1;
  }
}

@keyframes lift-card {
  0% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(0px);
    transform: rotateY(180deg) translateY(0%) translateZ(0px);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotateY(180deg) translateY(-110%) translateZ(0px);
    transform: rotateY(180deg) translateY(-110%) translateZ(0px);
    opacity: 0.6;
  }
  99% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(3px);
    transform: rotateY(180deg) translateY(0%) translateZ(3px);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: rotateY(180deg) translateY(0%) translateZ(3px);
    transform: rotateY(180deg) translateY(0%) translateZ(3px);
    opacity: 1;
  }
}

@-webkit-keyframes drop-env {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
  }
  99% {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
  100% {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
}

@keyframes drop-env {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
  }
  99% {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
  100% {
    -webkit-transform: translateY(5%);
    transform: translateY(5%);
  }
}

@-webkit-keyframes rotate-env {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

@keyframes rotate-env {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
}

@keyframes mail-up {
  0% {
    bottom: -30px;
  }
  100% {
    bottom: 10px;
  }
}

@keyframes rotate-mail {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
