// alert

.alert {
  &-success {
    background-color: var(--gf-primary-50);
    border-color: var(--gf-primary-100);
    color: var(--gf-primary-300);
  }
}

// input
.input--icon {
  width: 70% !important;
  margin: 30px auto !important;
  border-radius: var(--gf-border-radius-l);
  padding: 25px;
  border-right-color: transparent !important;
}

.input--icon + .input-group-append {
  align-items: center;
}

.input--icon + .input-group-append button {
  background-color: white;
  border-color: var(--gf-neutral-300);
  border-left-color: transparent;
  height: 52px;
  border-radius: 0 var(--gf-border-radius-l) var(--gf-border-radius-l) 0;
  svg {
    color: var(--gf-neutral-50) !important;
    background-color: var(--primary);
    padding: 10px 5px;
    border-radius: var(--gf-border-radius-l);
    width: 66px;
    height: 38px;
  }
}

.form-control:focus {
  border-color: var(--gf-neutral-300);
  outline: 0;
  box-shadow: none;
  // border-right-color: transparent;
}

// checkbox

.page-search {
  .form-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 3px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .form-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 9px;
    left: 16px;
    height: 15px;
    width: 15px;
    &[type='radio'] {
      top: 6px !important;
      left: 1em !important;
      opacity: 1;
      /* remove standard background appearance */
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* create custom radiobutton appearance */
      display: inline-block;
      width: 18px;
      height: 18px;
      padding: 3px;
      /* background-color only for content */
      background-clip: content-box;
      border: 2px solid #bbbbbb;
      background-color: transparent;
      border-radius: 50%;
      /* &:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      } */
      &:checked {
        background-color: black;
      }

      /*  &:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      } */
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 13px;
    left: 3px;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 2px solid #bbb;
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  .form-check:hover input ~ .checkmark {
    //   background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .form-check input:checked ~ .checkmark {
    //   background-color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .form-check input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .form-check .checkmark:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid #2e3a59;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
