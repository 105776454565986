.braintree-sheet {
  border-radius: 20px;
}

.top-up-modal .modal-content {
  border-radius: 8px;
}

.top-up-container {
  padding: 30px 15px 10px 15px;
  border: 1px solid #b5b5b5;
  border-radius: 20px;
}

input#credit-card-number {
  width: 100%;
  border-radius: 20px;
}

.braintree-sheet__content--form
  .braintree-form__field-group
  .braintree-form__field
  .braintree-form__hosted-field {
  width: 100% !important;
}

.react-tel-input {
  input:disabled {
    background-color: #e9ecef;
  }
  input {
    border-radius: 24px !important;
    width: 100% !important;
  }
  .flag-dropdown {
    border-top-left-radius: 24px !important;
    border-bottom-left-radius: 24px !important;
    .selected-flag {
      border-top-left-radius: 24px !important;
      border-bottom-left-radius: 24px !important;
    }
  }
}
