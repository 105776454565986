@media screen and (min-width: 1380px) {
  button.control-arrow.control-prev {
    left: 12% !important;
  }
  button.control-arrow.control-next {
    right: 12% !important;
  }
}
@media screen and (min-width: 1280px) {
  button.control-arrow.control-prev {
    left: 3% !important;
  }
  button.control-arrow.control-next {
    right: 3% !important;
  }
}

.modal-gift-box {
  .modal-header {
    padding: 0;
    .close {
      z-index: 30;
      & > span:first-child {
        font-size: 30px;
      }
    }
  }
  .modal-content {
    padding: 1em 15px;
    width: 90%;
    margin: 0 auto;
  }
}

// .carousel {
//   .slide img {
//     // height: 100%;
//     width: 100% !important;
//     object-fit: contain;
//   }
//   &__content {
//     &__container {
//       display: flex;
//       align-items: center;
//       min-height: 300px;
//     }
//     text-align: left;
//     .btn-link {
//       text-align: left;
//       color: var(--gf-primary-400);
//       letter-spacing: 1px;
//       text-decoration: underline;
//       padding-left: 0;
//     }
//   }
//   &__subtitle {
//     color: var(--gf-soft-orange-400);
//     text-align: left;
//     font-weight: 500;
//     font-size: 16px;
//     letter-spacing: 1px;
//     line-height: 21px;
//   }
//   &__title {
//     font-size: 24px;
//     font-weight: 700;
//     line-height: 1.2;
//     text-align: left;
//     color: black;
//   }
//   &__description {
//     font-size: 16px;
//     color: var(--gf-neutral-400);
//     font-weight: 400;
//     line-height: 19.2px;
//     text-align: left;
//   }
//   button.control-arrow {
//     z-index: 300 !important;
//     position: fixed !important;
//     width: 60px;
//     height: 60px;
//     background: var(--gf-primary-50) !important;
//     top: 0 !important;
//     bottom: 0 !important;
//     margin-top: auto !important;
//     margin-bottom: auto !important;
//     border-radius: 100%;
//   }
//   .control-prev {
//     left: 6% !important;
//     right: auto;
//   }
//   .control-next {
//     left: auto;
//     right: 6% !important;
//   }
//   .control-prev.control-arrow:before {
//     border-right: 8px solid var(--gf-dark-violet-300) !important;
//   }
//   .control-next.control-arrow:before {
//     border-left: 8px solid var(--gf-dark-violet-300) !important;
//   }

//   .gift-carousel-item {
//     min-height: 250px;
//   }

//   .slider-wrapper.axis-horizontal .slider .slide {
//     display: flex;
//     // justify-content: center;
//   }
// }

.gift-scroll-controller {
  .btn-horizontal-scroll {
    position: absolute;
    top: 15%;
    z-index: 10;
    bottom: 0;
    margin: auto;
    height: 60px;
    width: 60px;
    background-color: var(--gf-primary-200, #4eafa6);
    color: #ffffff;
    border: 0;
    border-radius: 100%;
    font-size: 28px;
    line-height: 0;
    &.left {
      left: -15px;
    }
    &.right {
      right: -10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .gift-scroll-controller {
    display: none;
  }
  // .page-merchant .carousel .slider-wrapper.axis-horizontal .slider .slide {
  //   justify-content: flex-start;
  // }

  .modal-gift-box {
    .modal-content {
      width: 95%;
    }
    .carousel .control-arrow {
      opacity: 1;
    }
    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid #fff !important;
    }
    .carousel .control-next.control-arrow:before {
      border-left: 8px solid #fff !important;
    }
  }

  .modal-gift-box .carousel__cta {
    margin-bottom: 0 !important;
  }
  // .carousel-wrapper,
  // .carousel,
  // .carousel > .slider-wrapper,
  // .carousel > .slider-wrapper > .slider {
  //   height: 100% !important;
  // }
}

@media screen and (max-width: 1023px) {
  button.control-arrow.control-prev {
    left: 2% !important;
  }
  button.control-arrow.control-next {
    right: 2% !important;
  }
}

@media screen and (min-width: 1023px) {
  .carousel__content {
    padding: 1em 2.4em;
  }
}
