@import './theme/variables.scss';
@import './theme/animation';
@import './theme/mediaquery';
@import './theme/utility';
@import '~bootstrap/scss/bootstrap';
@import './theme/nav';
@import './theme/base-component';

@import './theme/banner';
@import './theme/form';
@import './theme/carousel';
@import './theme/dropdown';
@import './theme/modal';

@import './theme/landing-page';

@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

// add global overide here

* {
  font-family: 'Lato';
}

html {
  background-color: #ffffff;
}

// body {
// background-color: #ffffff;
// }

a {
  text-decoration: none !important;
}

.App {
  padding-top: env(safe-area-inset-top);
}

.Toastify__toast-container--top-right {
  top: env(safe-area-inset-top);
}

.redemption-navbar {
  height: 78px;
  @include media-breakpoint-down(md) {
    height: 70px;
  }
  // @include media-breakpoint-down(sm){
  //   height: 50px;
  // }
}

.envelope-animation-app {
  background-color: transparent !important;
  .box-animated {
    background-color: transparent !important;
  }
}

// reset focus

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

//overide all btn-primary color to css variable
.btn-giftano {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--primary-contrast);
  border-radius: 20px;
}
.btn-giftano:hover {
  background-color: var(--primary-darker);
  border-color: var(--primary-darker);
  color: var(--primary-contrast);
  // animation: fadein 1.8s ease-in-out 0.8s forwards;
}

.btn.btn-primary {
  background-color: var(--primary);
  border-color: transparent;
  // width: 100%;
  // margin-top: 1.2em;
  transition: 0.3s ease-in background-color;
  border-radius: 24px;
  font-size: 14px;
  padding: 0.5rem 4.2rem;
  color: var(--primary-contrast);
  &:active {
    background-color: var(--primary-darker) !important;
    border-color: var(--primary-darker) !important;
  }
  &:hover {
    background-color: var(--primary-darker) !important;
    border-color: var(--primary-darker) !important;
  }
  &:focus {
    background-color: var(--primary-darker) !important;
    border-color: var(--primary-darker) !important;
  }
}
.btn.btn-outline-primary {
  background-color: var(--primary-contrast);
  border-color: var(--primary);
  // width: 100%;
  // margin-top: 1.2em;
  transition: 0.3s ease-in background-color;
  border-radius: 24px;
  font-size: 14px;
  padding: 0.5rem 4.2rem;
  color: var(--primary);
  &:active,
  &:focus,
  &:hover {
    background-color: var(--primary-darker) !important;
    border-color: var(--primary-darker) !important;
    color: var(--primary-contrast);
  }
}
.btn.btn-light {
  background-color: #ececed;
  border-color: transparent;
  // width: 100%;
  // margin-top: 1.2em;
  transition: 0.3s ease-in background-color;
  border-radius: 24px;
  font-size: 14px;
  padding: 0.5rem 4.2rem;
  color: #5c5c5c;
  &:active {
    background-color: #5c5c5c !important;
    border-color: #5c5c5c !important;
    color: white;
  }
}
.btn.btn-neutral {
  background-color: var(--gf-neutral-300);
  border-color: var(--gf-neutral-400);
  // width: 100%;
  // margin-top: 1.2em;
  transition: 0.3s ease-in background-color;
  border-radius: 24px;
  font-size: 14px;
  padding: 0.5rem 4.2rem;
  color: var(--gf-neutral-400);
  &:active {
    background-color: var(--gf-neutral-400) !important;
    border-color: var(--gf-neutral-400) !important;
    color: white;
  }
}

.btn.btn-transparent {
  background-color: transparent !important;
  border-color: transparent !important;
  &:active {
    background-color: transparent !important;
    border-color: transparent !important;
    color: var(--gf-primary-300) !important;
  }
}

.btn-halve {
  width: 18em;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.btn.btn-secondary {
  font-size: 14px;
  padding: 0.5rem 4.2rem;
}

.btn {
  &-done {
    position: absolute;
    bottom: 1em;
  }
  &-back {
    color: white;
    background-color: transparent;
    border-color: none;
    font-weight: bold;
  }
  &-back-merchant {
    color: var(--primary-color);
    background-color: transparent;
    border-color: none;
    font-weight: bold;
  }
  &-topup {
    border-radius: 30px;
    padding: 12px 2em;
    background-color: var(--gf-dark-violet-300);
    color: white;
  }
  &-top-up {
    &-submit {
      margin-top: 27px;
    }
  }
  &-envelope {
    background-color: transparent;
    border-color: transparent;
    color: $giftano-orange;
    border-radius: 30px;
    transition: 0.3s ease-in background-color;
    min-width: 18rem;
    padding: 0.5rem 4.2rem;
    svg {
      color: $giftano-orange;
    }

    &:hover {
      color: darken($color: $giftano-orange, $amount: 12);
      background-color: transparent;
      svg {
        color: darken($color: $giftano-orange, $amount: 12);
      }
    }
  }
  &-not-found {
    background-color: var(--primary);
    border-radius: 24px;
    color: #f1f1f1;
    border: none;
    font-size: 14px;
    line-height: 22px;
    padding-left: 24px;
    padding-right: 24px;
    height: 48px;
    text-transform: uppercase;
    &:hover {
      background-color: var(--primary-darker);
      border-color: var(--primary-darker);
      color: white;
      // animation: fadein 1.8s ease-in-out 0.8s forwards;
    }
  }
}

.disabled {
  &:after {
    background-color: transparent !important;
  }
}

.warning-topup {
  position: absolute;
  color: red;
  top: -0.6em;
  right: 1em;
  font-size: 14px;
}
.warning-search {
  color: red;
  font-size: 14px;
}

.text {
  &-giftano {
    color: var(--primary);
    font-weight: 600;
  }
  &-giftano-orange {
    color: $giftano-orange;
  }
}

.my-navbar {
  padding-top: calc(6px + env(safe-area-inset-top)) !important;
  // @include full-width;
  background-color: var(--giftano-card-redemption-nav-background-color);
  // background-color: var(--primary-contrast);
  box-shadow: var(--gf-shadow-thin);
  &-bottom {
    @include full-width;
    background-color: var(--giftano-card-redemption-nav-background-color);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    padding-bottom: 10px;
    .nav {
      &-balance {
        &-title {
          text-align: center;
          font-size: 20px;
        }
        &-count {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
    }
  }

  .nav {
    &-balance {
      padding: 8px 0.75rem;
      background: var(--gf-primary-50, #e6f4f2);
      border-radius: 4px;
      font-size: 12px;
      line-height: 16px;
      // color: $primary-contrast;
      // color: var(--giftano-card-redemption-nav-font-color, white);
      color: var(--gf-dark-violet-400, #2a2034);
      font-weight: bold;
      &-title {
        text-align: center;
      }
      &-count {
        font-size: 24px;
        line-height: 28.8px;
      }
    }
  }
  .navbar-nav {
    a {
      font-size: 12px;
      line-height: 18px;
      // color: #f2f2f2 !important;
    }
  }

  .btn-how-it-work {
    color: var(--giftano-card-redemption-nav-background-color);
    background-color: var(--giftano-card-redemption-nav-font-color);
    border-color: var(--giftano-card-redemption-nav-background-color);
    border-radius: var(--gf-border-radius-l);
    margin-right: 1em;
    &:hover {
      color: var(--primary-contrast) !important;
      border-color: var(--giftano-card-redemption-nav-font-color) !important;
      background-color: var(--primary-darker);
    }
    &.active {
      color: var(--primary-contrast) !important;
      border-color: var(--giftano-card-redemption-nav-font-color) !important;
      background-color: var(--primary-darker) !important;
    }
  }
}

.nav-tabs .nav-item.nav-link.active {
  border: 1px solid var(--primary) !important;
  background-color: var(--primary) !important;
  color: var(--primary-contrast) !important ;
}

.Toastify__toast {
  border-radius: 0.25rem !important;
}

.list-group-item {
  border-color: transparent;
  border-radius: 8px;
  padding: 3rem 1.25rem;
  .btn-link {
    padding: 0;
    margin-bottom: 1em;
    color: var(--primary);
  }
}

.img-logo {
  height: 100px;
  background-color: black;
  padding: 20px;
}

.envelope-animation {
  height: 100vh;
  background-image: var(--backdrop-image);
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  color: black;
}

.env-card--frontside {
  picture {
    display: inline-block;
    width: 90%;
    text-align: center;
    box-shadow: 0 0px 9px 3px rgba(73, 80, 87, 0.39);
  }
}

.skip-animation {
  animation: none;
  animation-fill-mode: none;
}

.section--cards {
  text-align: center;
  display: none;
  margin: auto;
}

.js-loading *,
.js-loading *:before,
.js-loading *:after {
  display: none;
  animation-play-state: paused !important;
}

.b__card-animated {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.env-wrapper {
  position: relative;
  display: inline-block;
  -webkit-transition: transform 2s;
  transition: transform 2s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.env-wrapper img {
  position: relative;
  width: 100%;
}

.env-wrapper.is-rotated {
  -webkit-animation: flip-env 1.8s linear forwards;
  animation: flip-env 1.8s linear forwards;
  animation-delay: $animation-delay;
  &.skip-animation {
    animation: flip-env 0s linear forwards;
    animation-delay: 0s;
  }
}

.env-wrapper.is-rotated .env-outer > .env-back {
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  -ms-backface-visibility: visible;
  backface-visibility: visible;
}

.env-wrapper.is-rotated .env-outer .env-top > .env-top--frontside {
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  -ms-backface-visibility: visible;
  backface-visibility: visible;
}

.env-outer {
  transform-style: preserve-3d;
}

.env-front {
  position: relative;
  z-index: 5;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(3px);
  transform: translateZ(3px);
}

.env-top {
  position: absolute;
  top: 0;
  z-index: 4;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.env-top.is-opened {
  -webkit-animation: open-env 1.2s linear 0.4s forwards;
  animation: open-env 1.2s linear 0.4s forwards;
  animation-delay: 1.8s + $animation-delay;
  &.skip-animation {
    animation: open-env 1.2s linear 0.4s forwards;
    animation-delay: 0s;
  }
}

.env-top.is-opened > .env-top--backside {
  opacity: 1;
  -webkit-backface-visibility: visible;
  -moz-backface-visibility: visible;
  -ms-backface-visibility: visible;
  backface-visibility: visible;
}

.env-top--frontside {
  position: relative;
  // top: -0.3px;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateY(180deg) translateZ(2px);
  transform: rotateY(180deg) translateZ(2px);
}

.env-top--backside {
  position: absolute;
  z-index: 0;
  opacity: 0;
  top: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate(180deg) translateZ(-2px);
  transform: rotate(180deg) translateZ(-2px);
}

.env-back {
  position: absolute;
  top: 0;
  z-index: 3;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateY(180deg) translateZ(1px);
  transform: rotateY(180deg) translateZ(1px);
}

.env-background {
  position: absolute;
  top: 0;
  z-index: 0;
  -webkit-transform: rotateY(180deg) translateZ(-1px);
  transform: rotateY(180deg) translateZ(-1px);
}

.env-card {
  opacity: 0;
  position: absolute;
  z-index: 2;
  bottom: 0;
  -webkit-transform: rotateY(180deg) translateZ(0px);
  transform: rotateY(180deg) translateZ(0px);
  -webkit-transition: transform 1.5s ease;
  transition: transform 1.5s ease;
}

.env-card img {
  width: 100%;
}

.env-card--frontside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
}

.input-heading {
  position: absolute;
  left: 0;
  right: 0;
  &.animation-logo {
    top: 3.21em;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 1rem;
    img {
      height: 2rem;
      width: auto;
    }
  }
  &.back-button {
    animation: fadein 1.8s ease-in-out 4.8s + $animation-delay forwards;
    &.skip-animation {
      animation: fadein 1.8s ease-in-out 4.8s + $animation-delay forwards;
      animation-delay: 0s;
      animation-duration: 0s;
    }
  }
}

.front-sender {
  font-family: var(--font-family), 'Allura', cursive;
  color: var(--font-color);
  font-size: var(--font-size);
  margin-top: 10px;
  span {
    font-family: var(--font-family), 'Allura', cursive;
  }
}

.front-text {
  line-height: 1.25;
}

.front-recipient {
  font-family: var(--font-family), 'Allura', cursive;
  color: var(--font-color);
  font-size: var(--font-size);
  margin-bottom: 10px;

  span {
    font-family: var(--font-family), 'Allura', cursive;
  }
}

.front-content {
  padding-left: 8px;
  padding-right: 8px;
  &.default {
    font-size: 16px;
  }
  &.mid {
    font-size: 8px;
  }
  &.long {
    font-size: 8px;
  }
  font-family: var(--font-family), 'Lateef', cursive;
  font-size: var(--font-size);
  font-weight: 400;
  color: var(--font-color);
  width: 75%;
  text-align: center;
  max-height: 8.1em;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.front-content::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
  display: none;
}

.front-content.default {
  font-size: 1.5vw !important;
}

.front-content.mid {
  font-size: 12px !important;
  max-height: 100% !important;
}

.front-content.long {
  font-size: 8px !important;
  max-height: 100% !important;
}

.front-recipient.default {
  font-size: 1.5vw !important;
}

.front-recipient.mid {
  font-size: 12px !important;
}

.front-recipient.long {
  font-size: 8px !important;
}
.front-sender.default {
  font-size: 1.5vw !important;
}

.front-sender.mid {
  font-size: 12px !important;
}

.front-sender.long {
  font-size: 8px !important;
}

.back-content {
  top: 35%;
  left: 18%;
}

.skip-button {
  padding: 10px 10px 10px 10px;
  text-align: right !important;
  color: var(--primary);
  background-color: transparent;
  border-radius: 5px;
  border: none;
  position: relative;
  margin-right: 39px;
  font-weight: bold;
  line-height: 14px;
  font-size: 12px;
  svg {
    position: absolute;
    left: 87%;
    top: 0;
    bottom: 0;
    height: 40px;
    width: 40px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
.skip-button:active,
.skip-button:focus {
  outline: none;
}

.back-button {
  opacity: 0;
  top: calc(100% + 15px);
  left: 16%;
  width: 70%;
  padding: 10px 10px 10px 10px;
  text-align: center !important;
  color: #ffffff;
  background-color: #229e93;
  border-radius: 5px;
  border: none;
}

.back-button:hover {
  cursor: pointer;
  background-color: #048378;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

// .spinner {
//   &-border {
//     color: var(--primary) !important;
//   }
// }

.text-primary {
  color: var(--primary) !important;
}
.text-warning {
  color: var(--gf-warning-300) !important;
}

.top-up {
  &-title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }
  &-modal {
    .modal-title {
      color: var(--primary);
    }
    // .modal-header {
    //   .close {
    //     margin-top: -3rem !important;
    //   }
    // }
  }
}

.gift-card-nav .btn-how-it-work {
  display: none;
}

.modal-pin {
  .modal-dialog {
    width: 500px;
    max-width: 95%;
  }

  .modal-content {
    // background: #e6f4f2;

    .close {
      border: none;
    }

    h4 {
      font-size: 24px;
      font-weight: 700;
    }

    .qr {
      padding: 10px;
      background: white;
      width: 220px;
      margin: 0 auto 20px;
    }

    .box-pin {
      border: 1px solid #68ada6;
      padding: 16px;
      text-align: left;
      background-color: white;
    }

    dl {
      margin: 0px;
    }

    dt {
      color: #028c80;
      font-size: 16px;
      font-weight: normal;
    }

    dd {
      font-size: 16px;
      font-weight: 900;
    }
  }
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.form {
  &-check {
    &-input {
      z-index: 20;
      margin-left: -1.05rem;
      bottom: 0.27em;
    }
    &-label {
      z-index: 90;
      position: relative;
      font-size: 0.85rem;
      line-height: 1.4rem;
    }
    // &:hover {
    //   .form-check-label {
    //     color: white;
    //   }
    // }
    /* &:hover:before {
      content: '';
      background-color: rgba(3, 175, 160, 81%);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 10;
      bottom: 0;
      margin: auto;
      border-radius: 3px;
    }*/
  }
  &-control {
    // border-radius: 20px;
  }
  button.btn.btn-primary.btn-block {
    border-radius: 20px;
  }
}

.modal-content {
  border: none;
}

.modal-header {
  // border-bottom: none;
  // padding: 1.5rem 1.5rem 1rem;
  // border-top: 1em solid var(--primary);
  border-bottom: 0;
  padding: 1.5rem;
  text-align: center;
  display: block;
}

.modal-title {
  font-size: 2.2em;
  font-weight: bold;
}

.modal-body {
  padding: 1.5rem 9%;
  .form {
    &-control {
      border-radius: 20px;
    }
  }
  ol {
    padding-left: 1em;
    li {
      margin-bottom: 6px;
    }
  }

  .howto {
    color: #7d7d7d;
    white-space: pre-wrap;
  }
  & > .row {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.modal-confirm {
  .modal-content {
    border-radius: 20px;
  }
  .modal-title {
    font-size: 1.5em;
    /* text-transform: uppercase; */
    color: black;
    line-height: 1.25;
  }
  &__action {
    display: flex;
    justify-content: space-evenly;
  }
  .attention {
    font-size: 24px;
    line-height: 1.2;
    font-weight: bold;
    &-quantity {
      color: black;
    }
  }
}

.underlined {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.modal-footer {
  border-top: 0;
  justify-content: center;
  &-submit {
    background-color: $giftano-orange !important;
    border-radius: 30px;
    color: white;
    padding: 10px 6em;
    border-color: $primary-color;
    transition: 0.3s ease-in background-color;
    &:hover,
    &:focus,
    &:active {
      background-color: darken($giftano-orange, 15%) !important;
      border-color: darken($giftano-orange, 15%) !important;
    }
  }
  &-cancel {
    background-color: $giftano-red !important;
    border-radius: 30px;
    color: white;
    padding: 10px 6em;
    border-color: $giftano-red;
    &:hover,
    &:focus,
    &:active {
      background-color: darken($giftano-red, 15%) !important;
      border-color: darken($giftano-red, 15%) !important;
    }
  }
}

.modal-header .close {
  margin-right: 0;
  margin-top: 28px;
  padding: 16px;
  border: 1px solid;
  border-radius: 100%;
  height: 1.4em;
  width: 1.4em;
  font-size: 1.5em;
  position: relative;
  background-color: transparent;
  opacity: 1;
  color: white;
  position: absolute;
  top: 0px;
  right: 1rem;
  &:focus {
    outline: none;
  }
}

.modal-header .close > span:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: -1rem;
  bottom: 0;
  margin: auto;
  line-height: 1;
  color: var(--primary);
  font-size: 48px;
}
.account-page__modal {
  .close > span:first-child {
    font-size: 32px;
  }
}

.gift-type {
  display: inline-block;
  background-color: $dark;
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
  width: fit-content;
  line-height: 14px;
  font-size: 10px;
  cursor: pointer;
  margin-bottom: 2em;
}

.footer {
  background-color: #2f243a;
  padding-top: 60px;
  padding-bottom: 30px;
  color: #ffffff;
  text-align: center;
  &-text {
    max-width: 300px;
    margin-bottom: 20px;
    font-size: 14px !important;
  }
  &-logo {
    width: 70px;
    height: 70px;
    max-height: 70px !important;
  }

  &-right {
    font-size: 12px !important;
  }
  &-address {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  &-contact {
    p {
      font-size: 14px !important;
      margin-bottom: 2px;
      font-weight: 700;
    }
  }
}

/* prevent scroll when modal open */
body.modal-open {
  overflow: hidden;
}

.modal-gift-box {
  .image-gift {
    width: 100%;
  }
  .carousel-control-prev {
    left: -120px;
  }
  .carousel-control-next {
    right: -120px;
  }
  .carousel-indicators {
    bottom: -70px;
  }
}

.modal-how-to-redeem {
  text-align: center;
  .icon {
    width: 74px;
  }
}

.btn-topup {
  &:hover {
    color: var(--primary-contrast) !important;
    border-color: var(--giftano-card-redemption-nav-font-color) !important;
    background-color: var(--primary-darker);
  }
}

.tab-bar-bottom {
  background: rgb(255 255 255 / 98%);
  height: 88px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 15px;
  z-index: 1000;
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  box-shadow: var(--gf-shadow-thin);
  border-top: 1px solid var(--gf-neutral-200);
  .tab-bar-button {
    text-align: center;
    color: var(--primary);
    font-size: 18px;
    padding: 5px;
    letter-spacing: 0.2px;
    position: relative;
    .label {
      font-size: 14px;
    }
    .gift-counter {
      background-color: #cc3125;
      color: white;
      padding: 0px;
      border-radius: 8px;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 36px;
      font-size: 12px;
      line-height: 12px;
      top: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.active {
      font-weight: bold;
      border-bottom: 2px solid var(--gf-dark-violet-300);
    }
  }
  .indicator {
    background: #ececed;
    height: 5px;
    width: 130px;
    border-radius: 2px;
    border: none;
    margin-top: 10px;
  }
}

.modal-check-version {
  .modal-dialog {
    height: calc(100% - 50px) !important;
    .modal-content {
      height: 100% !important;
      border-radius: 20px !important;
      .modal-body {
        padding-left: 0px !important;
        padding-right: 0px !important;
        img {
          width: 100%;
        }
        .text-outdated {
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #5c5c5c;
        }
      }
    }
    .modal-footer {
      padding: 30px 30px 37px 30px !important;
      button {
        width: 100% !important;
        height: 50px !important;
        font-weight: 700 !important;
        font-size: 16px !important;
      }
    }
  }
}

/* MOBILE KEYBOARD IS OPEN */
@media only screen and (max-width: 430px) and (max-height: 400px) {
  .tab-bar-bottom {
    display: none;
  }
  .passcode-page .recaptcha-text {
    display: none;
  }
}

/* hidden tab bar when desktop */
@media (min-width: 1024px) {
  .tab-bar-bottom {
    display: none;
  }
}

/* hidden footer when tablet and mobile */
@media (max-width: 1023px) {
  .footer {
    display: none;
  }
  // .App {
  //   padding-bottom: 50px;
  // }
  .navbar-toggler {
    display: none !important;
  }
}

@media (max-width: 1023px) {
  .page-getstarted {
    padding-bottom: 30px;
  }
}

// @media (min-width: 768px) {
// .redemption-navbar {
// height: 78px;
// margin-bottom: 24px;
// }
// }

.page-member-not-same {
  background: radial-gradient(309.45% 136% at 50% -41.94%, #eae9eb 12.05%, #028c80 92.58%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    width: 90%;
    max-width: 350px;
    text-align: center;
    padding: 20px 10px 30px;
    border-radius: 22px;
    .logo-not-same {
      width: 200px;
      height: auto;
      margin: 10px auto 30px;
    }
    .title-not-same {
      font-weight: 700;
      color: var(--gf-warning-300);
      font-size: 40px;
    }
    .desc-not-same {
      font-size: 16px;
      padding: 10px 5px;
    }
  }
}

// add custom style each page
@import './pages/Passcode.scss';
@import './pages/merchant/Merchant.scss';
@import './pages/my-gifts/MyGifts.scss';
@import './pages/search/Search';
@import './pages/get-started/GetStarted';
@import './pages/ModalHelp.scss';
@import './pages/Account.scss';
@import './pages/faqs/Faqs.scss';
@import './pages/HowToRedeem.scss';
@import './pages/info/InfoPage.scss';
@import './pages/info/AboutGiftanoCardPage.scss';
@import './pages/info/AboutGiftanoPage.scss';
@import './pages/perks/Perks.scss';
