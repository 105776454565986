.account-page {
  @include MyNavbar(64px);

  background: var(--gf-neutral-100);
}

.account-page__modal .modal-footer {
  display: flex !important;
  justify-content: space-around;
  button {
    width: 45%;
    padding: 10px;
  }
}
