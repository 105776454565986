@mixin font-14-light {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-14-bold {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-16-light {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.2 !important;
}
@mixin font-16-bold {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-18-light {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-18-bold {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-26-light {
  font-size: 26px;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-26-bold {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-24-light {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-24-bold {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}
@mixin font-32-light {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;
}
@mixin font-32-bold {
  font-size: 32px;
  font-weight: 900;
  line-height: 1.2;
}
@mixin font-41-bold {
  font-size: 41px;
  line-height: 1.2;
  font-weight: 700;
}
@mixin font-41-light {
  font-size: 41px;
  line-height: 1.2;
  font-weight: 400;
}

@mixin card-shading {
  padding: 15px;
  box-shadow: var(--gf-shadow-thin);
  border-radius: var(--gf-border-radius-m);
}

@mixin full-width-container {
  display: block;
  content: '';
  z-index: 0;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@mixin clampline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

// utility
.font-16-bold {
  @include font-16-bold;
}
.font-16-light {
  @include font-16-light;
}

.subtitle {
  @include font-26-bold;
  margin-bottom: 12px;
  line-height: 20px;
}
.desc {
  @include font-18-light;
  margin-bottom: 20px;
  color: #000000;
  font-style: normal;
}

.merchant-link {
  transition: 0.3s ease-in color;
  &:hover {
    color: var(--gf-soft-orange-500) !important;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  &:active {
    color: var(--gf-soft-orange-500) !important;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  &:focus {
    color: var(--gf-soft-orange-500) !important;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

.links {
  padding: 0 !important;
  margin-bottom: 1em;
  color: var(--primary) !important;
  font-weight: 500 !important;
  text-decoration: none;
}

.btn-list {
  border-radius: var(--gf-border-radius-l) !important;
  padding: 10px 20px !important;
  max-width: 175px;
  font-weight: 700 !important;
  font-size: 19px !important;
  line-height: 24px;
  // position: absolute;
  // right: 15px;
  // bottom: 0;
}
.btn-list__product {
  border-radius: var(--gf-border-radius-l) !important;
  padding: 6px 3em !important;
  // max-width: 175px;
  font-weight: 700 !important;
  font-size: 19px !important;
  line-height: 24px;
  // position: absolute;
  // right: 15px;
  // bottom: 0;
}

.text-orange {
  color: var(--gf-soft-orange-300);
}
