// * Specific Search page
.page-search {
  background-color: #f5f5f5;
  &__bar {
    &:after {
      // content: '';
      position: absolute;
      right: -15px;
      width: 2px;
      height: 38px;
      background: white;
      z-index: 10;
      top: 0;
    }
    &.hide:after {
      content: none;
    }
  }

  .listing-skeleton {
    display: block;
  }
  .listing-skeleton-mobile {
    display: none;
  }
  .listing-skeleton-tablet {
    display: none;
  }
  .redemption-navbar {
    margin-bottom: 17px;
  }
  .btn-back {
    padding: 0;
    margin-top: 5px;
    display: none;
  }
  .ptr {
    overflow: inherit;
  }
  .search-loader {
    position: absolute;
    top: 25%;
    bottom: 0;
  }
  .image-not-found {
    position: absolute;
    top: 30vh;
    left: 0;
    right: 0;
  }
  .footer {
    position: relative;
    width: 100%;
  }
  .rectangle-header {
    position: relative;
  }
  .float {
    position: sticky;
    z-index: 10;
    height: 60px;
    &:before {
      position: absolute;
      content: '';
      background-color: var(--dark);
      width: 100vw;
      height: calc(100% + 1em);
      z-index: 0;
      display: block;
      top: -17px;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }

  .page-search__utility .show + button + .dropdown--filter {
    position: absolute;
    left: 235px;
  }

  .lazyload-wrapper {
    &.is-cash {
      background-color: white;
      .merchant-image-wrapper > div {
        padding: 10px;
      }
    }
    // min-height: 6.3em;
    border-radius: 8px;
    // background-color: var(--primary);
    // background-image: var(--background-logo-url);
    // background-size: 80%;
    // background-repeat: no-repeat;
    // background-position: center center;
    position: relative;
    display: flex;
    justify-content: center;
    // box-shadow: var(--gf-shadow-normal);
    box-shadow: 5px 4px 10px rgba(92, 92, 92, 0.2);
    border-radius: 4px;
    align-items: flex-start;
    overflow: hidden;
    & > .merchant-images {
      height: auto;
    }
    .merchant-image-wrapper {
      width: 100%;
      .merchant-images {
        &.top-rad {
          border-radius: 4px 4px 0 0 !important;
          height: 100px;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .placeholder {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      &-gift {
        display: flex;
        flex-direction: column;
        width: 100%;
        // overflow: hidden;
        border-radius: 0 0 8px 8px;
        background-color: white;
        padding: 0.6em 1.2em;
        position: relative;
        .placeholder-coupon {
          // margin-top: 1em;
          color: #000;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.2em;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          min-height: 35px;
          max-height: 60px;
          text-align: left;
          padding-left: 0;
          padding-right: 0;
          margin-top: 3px;
        }
        .placeholder-icon {
          text-align: center;
          margin-top: 1em;
          margin-bottom: 1em;
        }
        .placeholder-price {
          // position: absolute;
          bottom: 100%;
          right: 0;
          color: var(--gf-primary-300);
          // background-color: #fc9e4f;
          // background-color: rgba(252, 158, 79, 0.8);
          padding: 3px 0px;
          @include font-16-bold;
          /* min-width: 50%; */
          text-align: left;
          // margin-bottom: 15px;
        }
        .placeholder-cta-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 60px;
        }
        .placeholder-cta-detail {
          width: 55%;
          padding: 5px 10px;
          display: inline-block;
          text-transform: uppercase;
        }
        .placeholder-brand {
          font-size: 10px;
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          min-height: 33px;
          max-height: 49px;
          text-align: left;
          color: #808080;
          margin-top: 10px;
        }
      }
      &-merchant {
        color: var(--gf-primary-300);
        text-align: left;
        font-size: 12px;
        text-transform: uppercase;
        // margin-bottom: 5px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        min-height: 18px;
        max-height: 45px;
      }
      &-coupon {
        color: white;
        text-align: center;
        font-size: 0.9em;
        padding-left: 6px;
        padding-right: 6px;
      }
      &-icon {
        text-align: center;
        min-height: 2.7em;
        margin-top: 2em;
      }
      // &-floating-price {
      //   position: absolute;
      //   background-color: rgba(252, 158, 79, 0.85);
      //   color: #000;
      //   right: 0;
      //   bottom: 0;
      //   padding: 3px 6px;
      //   border-radius: 2px 0 0 0;
      // }
    }
  }
  .dropdown {
    &-header {
      padding: 0.21rem 0.9rem;
      text-align: center;
      font-weight: bold;
      color: black;
    }
    &-categories {
      // max-height: 20vh;
      // overflow: scroll;
    }
    .form-control {
      background-color: #f7f7f7;
    }
    .dropdown-toggle[aria-expanded='true'] {
      // position: absolute;
      // right: 6px;
      // top: -35px;
    }

    &.show {
      &:before {
        display: block;
        // height: calc(100% - 213px);
        width: 100vw;
        position: fixed;
        margin-top: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(100, 100, 100, 0.5);
        content: '';
        z-index: -1;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &-menu {
      &.show {
        // transform: translate3d(-1.5rem, -3.2rem, 0px) !important;
        // transform: translate3d(-32%, 8%, 0px) !important;
        width: 21em;
        // transform: translate3d(-23px, 2px, 0px);

        .form-check-input {
          top: 7px;
          left: 20px;
        }
      }
    }
  }
  @include MyNavbar(128px);
  .nav-input {
    border-radius: 25px;
    overflow: hidden;
    .form-control {
      border: none;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    .btn {
      background-color: white;
      border-color: transparent;
    }
  }
  &__list {
    margin-top: 1.5rem;
    padding-bottom: 6rem;
    position: relative;
    // min-height: 80vh;
    min-height: calc(100vh - 162px);
  }
  &__item {
    margin-bottom: 25px;
  }
  &__utility {
    .dropdown {
      .btn-filter-action {
        color: white;
        &:hover {
          background-color: $primary-dark;
        }
      }
      .dropdown-toggle::after {
        // border-top: 0.3em solid var(--primary);
        content: none;
      }
      &--filter {
        position: relative;
      }
    }

    .btn-link {
      svg {
        fill: white;
      }
      &.btn {
        background-color: $giftano-orange;
        border-radius: 30px;
        // border: 1px solid white;
        min-width: 11.5em;
        color: white;
        font-size: 14px;
        transition: 0.3s ease-in background-color;
        font-weight: bold;
        padding: 8px 2em;
        &:hover {
          background-color: $giftano-orange;
          text-decoration: none;
        }
      }
    }
  }
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 6rem;
    margin-left: auto;
    margin-right: auto;
    color: gray;
    // top: 1rem;
  }
  .filter-count {
    // color: black;
    color: transparent;
    font-size: 8px;
    // position: absolute;

    // right: 10px;
    // background: white;
    background: $primary-color;
    border-radius: 100%;
    padding: 0;
    margin: 0;
    box-shadow: none;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
  .is-cash .merchant-images.cash-voucher-images.top-rad {
    /* height: 100% !important; */
    // width: 100% !important;
    width: auto !important;
    margin: 10px auto;

    height: 79px !important;
    width: auto;
    border-radius: 12px !important;
    box-shadow: 0px 0px 10px -1px rgba(10, 11, 12, 0.2);
  }
  .disabled-wrapper {
    top: 60px;
  }
}
.filter-modal {
  .modal-header {
    border-top: 0;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;
    font-weight: bold;
  }
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border: 2px solid #5c5c5c;
    border-radius: 3px;
  }
  .form-check {
    padding-left: 0;
  }
  /* On mouse-over, add a grey background color */
  /* .form-check:hover input ~ .checkmark {
    background-color: $primary-color;
  } */

  .form-check-input {
    visibility: hidden;
  }
  .form-check-label {
    padding-left: 24px;
    margin-left: 0;
    &:hover {
      cursor: pointer;
    }
  }

  /* When the checkbox is checked, add a blue background */
  .form-check input:checked ~ .checkmark {
    background-color: $primary-color;
    border-color: $primary-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .form-check input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .form-check .checkmark:after {
    left: 4px;
    top: 0;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .button-scroll-to-top {
    border-radius: 20px;
  }
}

@media (hover: hover) {
  .filter-modal {
    .form-check:hover input ~ .checkmark {
      background-color: var(--primary);
    }
  }
}

@media only screen and (max-width: 767px) {
  /* .filter-modal {
    .form-check:hover input ~ .checkmark {
      background-color: var(--primary);
    }
  } */
  .btn-halve {
    width: 10em;
  }

  .listing-skeleton {
    display: none !important;
  }
  .listing-skeleton-mobile {
    display: block !important;
  }
  .listing-skeleton-tablet {
    display: none !important;
  }
  .page-search {
    .redemption-navbar {
      margin-bottom: 0;
    }
    &__bar {
      overflow: hidden;
    }
    &__item {
      margin-bottom: 0;
      padding: 5px;
    }
    // .btn-back {
    //   display: none;
    // }
    .float {
      height: auto;
      padding-bottom: 15px;
      top: calc(70px + env(safe-area-inset-top));
    }
    &__list {
      margin-top: 5px;
    }
    &__utility {
      // justify-content: space-around;
      float: none !important;
      position: relative !important;
      margin: auto;
      justify-content: space-around;
      .btn-link.btn {
        min-width: 44vw;
      }
    }
    .page-search__utility > div {
      // width: 24%;
    }
    .page-search__utility .show + button + .dropdown--filter {
      left: 69vw;
    }
    .page-search__utility .show + button {
      // left: 33vw;
    }

    .page-search__utility > .btn {
      // width: 40%;
    }
    .dropdown .dropdown-toggle[aria-expanded='true'] {
      // top: 0 !important;
      // right: 0 !important;
      // transform: translateX(-33vw);
    }
    .dropdown--filter.show.dropdown {
      width: 24%;
    }
    .dropdown-menu.show {
      // transform: translate3d(-256px, 37px, 0px) !important;
    }
    .footer {
      bottom: auto;
    }

    .lazyload-wrapper {
      .placeholder {
        top: 0;
        bottom: 0;
      }
      .placeholder-merchant {
        font-size: 12px;
        // margin-top: 0.6em;
      }
      .placeholder-icon {
        min-height: 0;
        margin-top: 0;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .placeholder-gift {
        .placeholder-cta-container {
          min-height: 45px;
        }
        padding: 0.6em;
        .placeholder-cta-detail {
          // margin-left: 3px;
          width: auto;
        }
        .placeholder-price {
          line-height: 1.2;
          font-size: 14px;
          width: 35%;
          // width: 42%;
        }
      }
    }

    .disabled-wrapper {
      top: 80px;
    }
  }
}
@media (min-width: 768px) {
  .page-search .redemption-navbar {
    margin-bottom: 0;
  }
  .page-search .float {
    top: 82px;
  }

  .page-search .float > div {
    position: sticky;
    z-index: 40;
    width: 100%;
    /* top: 100px; */
    /* margin-top: -80px; */
  }

  .page-search .float > div:after {
    display: block;
    content: '';
    z-index: -1;
    width: 100vw;
    height: calc(100% + 1em);
    position: absolute;
    top: -18px;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: var(--gf-dark-violet-300);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-search {
    .lazyload-wrapper {
      .placeholder-gift {
        .placeholder-cta-detail {
          width: 100%;
          margin-bottom: 6px;
        }
        .placeholder-price {
          width: 100%;
          margin-bottom: 10px;
        }
        .placeholder-cta-container {
          display: block;
        }
      }
    }
    .listing-skeleton {
      display: none !important;
    }
    .listing-skeleton-mobile {
      display: none !important;
    }
    .listing-skeleton-tablet {
      display: block !important;
    }
  }
}
@media only screen and (min-width: 1024px) {
  .page-search {
    .float {
      top: 94px;
    }
    .dropdown {
      // &-menu {
      //   &.show {
      //     transform: translate3d(0, 25px, 0px) !important;
      //     position: relative !important;
      //   }
      // }
      .dropdown-toggle[aria-expanded='true'] {
        // transform: translateX(-18px);
      }
    }
    // .page-search__utility .show + button {
    //   position: absolute;
    //   left: 140px;
    // }
  }
}

.filter-header {
  // border-top: 1px solid #e9ecef;
  // border-bottom: 1px solid #e9ecef;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  padding: 16px 25px;
  background-color: $lightgreen;
  margin-bottom: 0;
}
.form-check-input {
  bottom: none;
  top: 1px;
}

.price-slider {
  height: 42px;
}
.price-track {
  background: #02afa0;
  height: 20px;
  margin-top: 10px;
  border-radius: 20px;
}
.price-track-0,
.price-track-2 {
  background: #f7f7f7;
  background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
  background-repeat: repeat-x;
}
.price-thumb {
  background: #f99e4b;
  color: white;
  border-radius: 25%;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  top: 7px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
}

.slider-input {
  border: 1px solid #f99e4b;
  border-radius: 20px;
  margin-top: 15px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
  /*max-width: 130px; */
  /* margin-left: auto; */
  /* margin-right: auto; */
}
