.about-giftano-page {
  @include MyNavbar(64px);
  padding-bottom: 50px;
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
  .about {
    &-heading {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    &-content {
      font-weight: 14px;
      letter-spacing: 0.02em;
      margin-bottom: 24px;
    }
  }
}
